import React from "react";
import {
  StyledLoginConfirmButton,
  StyledLoginConfirmText,
  StyledLoginConfirmWrapper,
} from "./index.style";
import IdentityContent from "@/components/identityContent";
import { StyledButtonLink } from "@/uikit";

interface ILoginConfirm {
  isPending: boolean;
  onSubmit: () => void;
  onEmailResend: () => void;
}

const LoginConfirm: React.FC<ILoginConfirm> = (props) => {
  const { isPending, onSubmit, onEmailResend } = props;

  return (
    <IdentityContent title="Подтверждение электронной почты">
      <StyledLoginConfirmWrapper>
        <StyledLoginConfirmText>
          Вам было отправлено письмо с инструкцией для подтверждения адреса .
        </StyledLoginConfirmText>
        <StyledLoginConfirmText>
          Если вы не получили письмо с кодом
          <br /> подтверждения,{" "}
          <StyledButtonLink onClick={onEmailResend}>
            отправьте письмо повторно
          </StyledButtonLink>
          .
        </StyledLoginConfirmText>
        <StyledLoginConfirmButton
          isLoading={isPending}
          kind="primary"
          size="small"
          onClick={onSubmit}
        >
          Понятно
        </StyledLoginConfirmButton>
      </StyledLoginConfirmWrapper>
    </IdentityContent>
  );
};

export default LoginConfirm;
