import * as Stitches from "@stitches/react";
import React from "react";
import { styled } from "stitches.config";

export const StyledText = styled("div", {
  color: "$text",

  variants: {
    bold: {
      true: {
        fontWeight: "$bold",
      },
    },
    weight: {
      medium: {
        fontWeight: "$medium",
      },
      regular: {
        fontWeight: "$regular",
      },
      semiBold: {
        fontWeight: "$semiBold",
      },
    },
    kind: {
      ghost: {
        color: "$textGhost",
      },
      secondary: {
        color: "$textSecondary",
      },
      error: {
        color: "$textError",
      },
      warning: {
        color: "$textWarning",
      },
      success: {
        color: "$textSuccess",
      },
      primary: {
        color: "$primary",
      },
      default: {
        color: "$text",
      },
    },
    size: {
      giant: {
        fontSize: "$l",
        lineHeight: `$xl`,
      },
      large: {
        fontSize: "$s",
        lineHeight: `$s`,

        "@l": {
          fontSize: "$m",
          lineHeight: `$l`,
        },
      },
      medium: {
        fontSize: "$s",
        lineHeight: `$s`,

        "@l": {
          fontSize: "$s",
          lineHeight: `$m`,
        },
      },
      small: {
        fontSize: `$xs`,
        lineHeight: `$xs`,
      },
      tiny: {
        fontSize: `$xxs`,
        lineHeight: `$xxs`,
      },
    },
  },
  defaultVariants: {
    size: "medium",
  },
});

export type StyledTextVariants = Stitches.VariantProps<typeof StyledText>;
export type TextProps = Pick<
  StyledTextVariants,
  "bold" | "size" | "kind" | "weight"
> & {
  children?: React.ReactNode;
  className?: string;
  css?: Stitches.CSS;
};

export const Text: React.FC<TextProps> = ({
  children,
  size,
  bold,
  kind,
  weight,
  className,
  css,
}) => {
  return (
    <StyledText
      size={size}
      bold={bold}
      kind={kind}
      className={className}
      weight={weight}
      css={css}
    >
      {children}
    </StyledText>
  );
};
