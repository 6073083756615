import Modal from "@/components/modal";
import { Button } from "@/uikit";
import React from "react";
import {
  StyledModalPasswordConfirmSubTitle,
  StyledModalPasswordConfirmTitle,
  StyledModalPasswordConfirmWrapper,
} from "./index.style";

export type ModalPasswordConfirmProps = {
  isActive: boolean;
  onClose: () => void;
};

const ModalPasswordConfirm: React.FC<ModalPasswordConfirmProps> = (props) => {
  const { isActive, onClose } = props;

  return (
    <Modal isOpen={isActive} onClose={onClose}>
      <StyledModalPasswordConfirmWrapper>
        <StyledModalPasswordConfirmTitle level="3">
          Изменение пароля
        </StyledModalPasswordConfirmTitle>
        <StyledModalPasswordConfirmSubTitle>
          Новый пароль успешно установлен
        </StyledModalPasswordConfirmSubTitle>
        <Button kind="primary" size="small" onClick={onClose} isBlock={false}>
          Понятно
        </Button>
      </StyledModalPasswordConfirmWrapper>
    </Modal>
  );
};

export default ModalPasswordConfirm;
