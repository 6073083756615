import {
  EyeClosedIcon,
  EyeOpenIcon,
  MagnifyingGlassIcon,
} from "@radix-ui/react-icons";
import React, { ChangeEvent, RefObject, useState } from "react";
import { styled } from "stitches.config";
import {
  StyledErrorMessage,
  StyledFieldBox,
  StyledFieldIcon,
  StyledFieldLabel,
} from "../styles";

export type InputProps = {
  name: string;
  label?: string;
  placeholder?: string;
  defaultValue?: string;
  value?: string;
  isCircle?: boolean;
  error?: string;
  isError?: boolean;
  inputRef?: RefObject<HTMLInputElement>;
  size?: "default" | "small" | "large";
  type?: "password" | "number" | "text" | "search";
  isDisabled?: boolean;
  isBlock?: boolean;
  readOnly?: boolean;
  className?: string;
  autoComplete?: string;
  onChange?: (value: string) => void;
  onFocus?: () => void;
  onBlur?: () => void;
};

export const StyledInput = styled("input", {
  all: "unset",
  margin: "0",
  width: "100%",
});

export const Input: React.FC<InputProps> = React.memo(
  ({
    className,
    label,
    name,
    size,
    value,
    isDisabled,
    isBlock,
    onChange,
    onBlur,
    onFocus,
    type,
    defaultValue,
    isCircle,
    placeholder,
    readOnly,
    error,
    isError,
    ...props
  }) => {
    const [isPasswordShown, setPasswordShown] = useState<boolean>(false);
    const [isExistContent, setIsExistContent] = useState(
      !!defaultValue?.length
    );

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
      setIsExistContent(!!event?.currentTarget?.value?.length);
      if (onChange) {
        onChange(event.target.value);
      }
    };

    return (
      <StyledFieldBox
        isIcon={Boolean(type === "password") || Boolean(type === "search")}
        size={size}
        className={className}
        isDisabled={isDisabled}
        isBlock={isBlock}
        data-label={label}
        data-exist-content={isExistContent}
        isCircle={isCircle}
        isError={isError || !!error}
      >
        {label && <StyledFieldLabel>{label}</StyledFieldLabel>}
        <StyledInput
          {...props}
          data-field-inner
          aria-labelledby={label}
          id={name}
          name={name}
          disabled={isDisabled}
          value={value}
          defaultValue={defaultValue}
          onChange={handleChange}
          onFocus={onFocus}
          onBlur={onBlur}
          type={isPasswordShown ? "text" : type}
          placeholder={!label ? placeholder : undefined}
          readOnly={readOnly}
        />
        {type === "password" && (
          <StyledFieldIcon
            role="button"
            aria-pressed="false"
            // aria-disabled={String(disabled)}
            onClick={() => setPasswordShown((prevState) => !prevState)}
          >
            {isPasswordShown ? <EyeOpenIcon /> : <EyeClosedIcon />}
          </StyledFieldIcon>
        )}
        {type === "search" && (
          <StyledFieldIcon>
            <MagnifyingGlassIcon />
          </StyledFieldIcon>
        )}
        {error?.length ? (
          <StyledErrorMessage>{error}</StyledErrorMessage>
        ) : (
          <></>
        )}
      </StyledFieldBox>
    );
  }
);

Input.displayName = "Input";
