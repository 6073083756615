import { Text, Button } from "@/uikit";
import { styled } from "stitches.config";

export const StyledRegistrationConfirmText = styled(Text, {
  fontStyle: "normal",
  fontWeight: "400",
  fontSize: "14px",
  lineHeight: "20px",
  textAlign: "center",
  color: "$black",
  width: "95%",
});

export const StyledRegistrationConfirmBold = styled("p", {
  fontStyle: "normal",
  fontWeight: "700",
  fontSize: "14px",
  lineHeight: "20px",
  textAlign: "center",
  color: "$black",
  display: "inline",
});

export const StyledRegistrationConfirmButton = styled(Button, {
  marginTop: "56px",
  width: "100%",
});
