export const ShowPassIcon = () => (
  <svg
    width="12"
    height="8"
    viewBox="0 0 12 8"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.00029 5.50402C6.83107 5.50402 7.50455 4.83053 7.50455 3.99975C7.50455 3.16897 6.83107 2.49548 6.00029 2.49548C5.1695 2.49548 4.49602 3.16897 4.49602 3.99975C4.49602 4.83053 5.1695 5.50402 6.00029 5.50402Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.00029 0.741211C3.64781 0.741211 1.69175 1.99781 0.523352 3.86725C0.472685 3.94832 0.472685 4.05119 0.523352 4.13225C1.69175 6.00168 3.64781 7.25828 6.00029 7.25828C8.35276 7.25828 10.3088 6.00168 11.4772 4.13225C11.5279 4.05119 11.5279 3.94832 11.4772 3.86725C10.3088 1.99781 8.35276 0.741211 6.00029 0.741211ZM6.00029 6.75828C3.89525 6.75828 2.12797 5.66618 1.0323 3.99975C2.12797 2.33331 3.89525 1.24121 6.00029 1.24121C8.10532 1.24121 9.8726 2.33331 10.9683 3.99975C9.8726 5.66618 8.10532 6.75828 6.00029 6.75828Z"
      fill="currentColor"
    />
  </svg>
);
