import React, { useEffect, useState } from "react";
import RegistrationConfirm from "@/components/registration/registrationConfirm";
import LoginConfirm from "@/components/login/loginConfirm";
import { useVerificationHandler } from "@/ory/hooks";
import { useGlobalState } from "@/contexts/GlobalContext";
import { useLocation, useNavigate } from "react-router-dom";

const Verification: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const state = location?.state as { email: string };
  const [globalState] = useGlobalState();
  const [isEmailResented, setIsEmailResented] = useState(false);
  const [isEmailResentPending, setIsEmailResentPending] = useState(false);
  const { resendEmail } = useVerificationHandler();

  useEffect(() => {
    if (!state?.email) navigate("/login");
  }, [state?.email]);

  const handleEmailResend = async () => {
    setIsEmailResentPending(true);

    try {
      await resendEmail(state?.email);
      setIsEmailResented(true);
    } finally {
      setIsEmailResentPending(false);
    }
  };

  const handleResentSubmit = () => {
    navigate("/login");
  };

  return isEmailResented ? (
    <RegistrationConfirm onSubmit={handleResentSubmit} email={state?.email} />
  ) : globalState.isVerificationRequired ? (
    <LoginConfirm
      onSubmit={() => ({})}
      onEmailResend={handleEmailResend}
      isPending={isEmailResentPending}
    />
  ) : (
    <></>
  );
};

export default Verification;
