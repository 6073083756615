import {
  DropdownMenuCheckboxItemProps,
  DropdownMenuContentProps,
  DropdownMenuProps,
  DropdownMenuRadioItemProps,
  Arrow as DropdownArrow,
} from "@radix-ui/react-dropdown-menu";
import React from "react";
import * as DropdownMenuPrimitive from "@radix-ui/react-dropdown-menu";
import { CheckIcon } from "@radix-ui/react-icons";
import { styled } from "stitches.config";
import { Link } from "react-router-dom";

export const dropdownItemStyle = {
  all: "unset",
  fontSize: "$s",
  lineHeight: `$s`,
  color: "black",
  borderRadius: "2px",
  display: "flex",
  alignItems: "center",
  padding: "8px 16px",
  position: "relative",
  userSelect: "none",
  cursor: "pointer",

  "&[data-disabled]": {
    color: "green",
    pointerEvents: "none",
  },

  '&[data-state="checked"]': {
    color: "$primary",
  },

  "&:focus": {
    background: "$grey25",
  },

  "&:hover": {
    background: "$grey25",
  },
};

export const DropdownMenu: React.FC<DropdownMenuProps> = ({
  children,
  ...options
}) => (
  <DropdownMenuPrimitive.Root modal={false} {...options}>
    {children}
  </DropdownMenuPrimitive.Root>
);
export const DropdownMenuTrigger = DropdownMenuPrimitive.Trigger;

const StyledContent = styled(DropdownMenuPrimitive.Content, {
  minWidth: 240,
  background: "#FFFFFF",
  boxShadow: "0px 8px 20px rgba(0, 0, 0, 0.1)",
  borderRadius: "6px",
  overflow: "hidden",
  "@media (prefers-reduced-motion: no-preference)": {
    animationDuration: "400ms",
    animationTimingFunction: "cubic-bezier(0.16, 1, 0.3, 1)",
    animationFillMode: "forwards",
    // willChange: 'transform, opacity',
    // '&[data-state="open"]': {
    //   '&[data-side="top"]': { animationName: slideDownAndFade },
    //   '&[data-side="right"]': { animationName: slideLeftAndFade },
    //   '&[data-side="bottom"]': { animationName: slideUpAndFade },
    //   '&[data-side="left"]': { animationName: slideRightAndFade },
    // },
  },
});

const Arrow = styled(DropdownArrow, {
  fill: "$white",
});

export const DropdownMenuContent = React.forwardRef<
  HTMLDivElement,
  DropdownMenuContentProps & { withArrow?: boolean }
>(({ children, withArrow = false, ...props }, forwardedRef) => {
  return (
    <StyledContent {...props} ref={forwardedRef}>
      {withArrow && <Arrow offset={20} width={20} height={8} />}
      {children}
    </StyledContent>
  );
});

DropdownMenuContent.displayName = "DropdownMenuContent";

export const DropdownMenuLabel = DropdownMenuPrimitive.Label;
export const DropdownMenuGroup = DropdownMenuPrimitive.Group;
export const DropdownMenuItem = DropdownMenuPrimitive.Item;
export const StyledDropdownLink = styled(Link, {
  ...dropdownItemStyle,
});

const StyledCheckboxItem = styled(DropdownMenuPrimitive.CheckboxItem, {
  ...dropdownItemStyle,
  gap: "6px",
  "justify-content": "space-between",
});

const StyledItemIndicator = styled(DropdownMenuPrimitive.ItemIndicator, {
  // position: 'absolute',
  // left: '16px',
  // width: 25,
  display: "inline-flex",
  alignItems: "center",
  justifyContent: "center",
});

export const DropdownMenuCheckboxItem = React.forwardRef<
  HTMLDivElement,
  DropdownMenuCheckboxItemProps
>(({ children, ...props }, forwardedRef) => {
  return (
    <StyledCheckboxItem {...props} ref={forwardedRef}>
      {children}
      <StyledItemIndicator>
        <CheckIcon />
      </StyledItemIndicator>
    </StyledCheckboxItem>
  );
});

DropdownMenuCheckboxItem.displayName = "DropdownMenuCheckboxItem";

export const DropdownMenuRadioGroup = DropdownMenuPrimitive.RadioGroup;

export const DropdownMenuRadioItem = React.forwardRef<
  HTMLDivElement,
  DropdownMenuRadioItemProps
>(({ children, ...props }, forwardedRef) => {
  return (
    <DropdownMenuPrimitive.RadioItem {...props} ref={forwardedRef}>
      {children}
      <DropdownMenuPrimitive.ItemIndicator>
        <CheckIcon />
      </DropdownMenuPrimitive.ItemIndicator>
    </DropdownMenuPrimitive.RadioItem>
  );
});

DropdownMenuRadioItem.displayName = "DropdownMenuRadioItem";

export const DropdownMenuSeparator = DropdownMenuPrimitive.Separator;
