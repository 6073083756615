import { colors } from "@/helpers/styleColors";
import styled from "styled-components";
import {Checkbox} from "@/uikit";

export const StyledLoginLinkTabs = styled.div`
  margin-top: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 16px;
`;

export const StyledLoginLinkTab = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: ${colors.black};
`;

export const StyledLoginFormWrapper = styled.form`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`;

export const StyledLoginInputList = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  margin-top: 48px;
`;

export const StyledLoginInputItem = styled.div`
  width: 100%;
  margin-bottom: 32px;
  cursor: pointer;
`;

export const StyledLoginLoginButton = styled.div`
  width: 100%;
  margin-top: 24px;
  cursor: pointer;
  & .LoginLoginButton {
    width: 100%;
  }
`;

export const StyledLoginResetFormLink = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 24px;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: ${colors.green400};
`;
