import { styled } from "stitches.config";

export const StyledFieldIcon = styled("div", {
  position: "absolute",
  top: "50%",
  right: "12px",
  height: "24px",
  transform: "translate(0%, -50%)",
  padding: "0 !important",
  width: "max-content !important",

  '&[role="button"]': {
    cursor: "pointer",
  },

  "&[aria-disabled]": {
    cursor: "default",
    "pointer-events": "none",
  },

  "& svg": {
    width: "24px",
    height: "24px",
  },
});

export const StyledFieldLabel = styled("div", {
  display: "none",
  color: "$grey300",
  userSelect: "none",
  pointerEvents: "none",
});

const focusStyles = {
  // borderColor: '$inputBorderColorFocus',
  boxShadow: "inset 0 0 0 1px $colors$inputBorderColorFocus",
};

const focusLabelStyles = {
  [`& ${StyledFieldLabel}`]: {
    transform: "translate(0, calc($space$inputPaddingY / 2))",
    fontSize: "$$labelFontSize",
    lineHeight: "$$labelLineHeight",
  },
  "& [data-field-inner]": {
    padding:
      "calc(($inputPaddingY / 2) + $$labelLineHeight + 2px) $inputPaddingX calc($inputPaddingY / 2)",
  },
};

export const StyledFieldBox = styled("div", {
  $$labelFontSize: "$fontSizes$xs",
  $$labelLineHeight: "$lineHeights$xs",
  minWidth: "200px",
  width: "max-content",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "flex-start",
  fontSize: "$s",
  lineHeight: "$s",
  // overflow: "hidden",

  background: "#FFFFFF",
  // border: '1px solid $inputBorderColor',
  boxShadow: "inset 0 0 0 1px $colors$inputBorderColor",
  borderRadius: "$fieldBr",
  position: "relative",
  boxSizing: "border-box",
  transition: "0.3s",
  color: "$black",
  willChange: "border-color, box-shadow",

  "&:focus-within": {
    ...focusStyles,
  },

  "& [data-field-inner]": {
    fontSize: "$s",
    lineHeight: "$s",
    color: "inherit",
    boxSizing: "border-box",
  },

  "&[data-label]": {
    [`& ${StyledFieldLabel}`]: {
      position: "absolute",
      left: "$inputSmallPaddingX",
      top: "0",
      transition: "font-size, line-height, transform 0.1s",
      willChange: "font-size, line-height, transform",
      display: "block",
      transform: "translate(0, $space$inputPaddingY)",
    },
  },

  variants: {
    size: {
      small: {
        "&:focus-within": {
          [`& ${StyledFieldLabel}`]: {
            display: "none",
          },
        },

        "& [data-field-inner]": {
          padding:
            "$inputSmallPaddingTop $inputSmallPaddingX $inputSmallPaddingBottom",
        },
      },
      default: {
        "&[data-label]": {
          "&:focus-within": {
            ...focusLabelStyles,
          },
          '&[data-exist-content="true"]': {
            ...focusLabelStyles,
          },
        },

        "& [data-field-inner]": {
          padding: "$inputPaddingY $inputPaddingX",
        },
      },
      large: {
        "&:focus-within": {
          [`& ${StyledFieldLabel}`]: {
            top: "$inputLargePaddingBottom",
          },
        },

        "& [data-field-inner]": {
          padding:
            "$inputLargePaddingTop $inputLargePaddingX $inputLargePaddingBottom",
        },
      },
    },

    isIcon: { true: {} },
    isFocus: {
      true: {
        ...focusStyles,
      },
    },
    isCircle: {
      true: {
        borderRadius: "$fieldCircleBr",
      },
    },

    isBlock: {
      true: {
        width: "100%",
      },
    },

    isDisabled: {
      true: {
        backgroundColor: "$grey50",
        // borderColor: '$grey50',
        boxShadow: "inset 0 0 0 1px $colors$grey50",
        color: "$grey300",
        "& [data-field-inner]": {
          cursor: "default",
        },
      },
    },

    isError: {
      true: {
        // borderColor: '$red200',
        boxShadow: "inset 0 0 0 1px $colors$red200",
        "&:focus-within": {
          boxShadow: "inset 0 0 0 1px $colors$red200",
        },
      },
    },

    readOnly: {
      true: {
        backgroundColor: "$grey50",
        boxShadow: "inset 0 0 0 1px $colors$grey50",
        // borderColor: '$grey50',
        color: "$grey300",
      },
    },
  },

  defaultVariants: {
    size: "default",
  },

  compoundVariants: [
    {
      isIcon: true,
      size: "default",
      css: {
        "& [data-field-inner]": {
          paddingRight: "calc(($inputPaddingX * 2) + $sizes$fieldIconSize)",
        },
        [`& ${StyledFieldIcon}`]: {
          width: "$fieldIconSize",
          padding: "0 $inputPaddingX",
        },
      },
    },
  ],
});

export const toggleStyles = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  padding: "$togglePadding",
  borderRadius: "$mainBr",
  fontSize: "$s",
  lineHeight: "$s",
  background: "$grey25",
  color: "$grey300",
  cursor: "pointer",
  outline: "none",

  "&:hover": {
    // backgroundColor: violet.violet3,
  },
  "&[data-state=on]": {
    background: "$white",
    color: "$green400",
  },
};

export const StyledErrorMessage = styled("p", {
  position: "absolute",
  top: "calc(100% + 4px)",
  marginTop: "4px",
  fontStyle: "normal",
  fontWeight: "400",
  fontSize: "10px",
  lineHeight: "10px",
  color: "$red200",
});
