import * as Stitches from "@stitches/react";
import React from "react";
import { styled } from "stitches.config";

const StyledSpace = styled("div", {
  display: "flex",

  variants: {
    size: {
      small: {
        gap: "$contentBlockSmallMobileGap",

        "@l": {
          gap: "$contentBlockSmallGap",
        },
      },
      default: {
        gap: "$contentBlockMobileGap",

        "@l": {
          gap: "$contentBlockGap",
        },
      },
    },
    orientation: {
      vertical: {
        flexDirection: "column",
      },
      horizontal: {
        flexDirection: "row",
      },
    },
  },

  defaultVariants: {
    size: "default",
    orientation: "vertical",
  },
});

const StyledSpaceItem = styled("div", {});

export type SpaceProps = Stitches.VariantProps<typeof StyledSpace> & {
  children?: React.ReactNode;
};

export const Space: React.FC<SpaceProps> = ({ children, ...props }) => {
  const wrappedChildren = React.Children.map(children, (child) => {
    if (React.isValidElement(child)) {
      return <StyledSpaceItem>{child}</StyledSpaceItem>;
    }

    return child;
  });

  return <StyledSpace {...props}>{wrappedChildren}</StyledSpace>;
};
