import { CheckIcon, ChevronDownIcon } from "@radix-ui/react-icons";
import * as Select from "@radix-ui/react-select";
import { VisuallyHidden } from "@radix-ui/react-visually-hidden";
import { useEffect, useRef, useState } from "react";
import { styled } from "stitches.config";
import { setNativeSelectValue } from "@/uikit/utils";

export type SelectOption = { id: string; title: string };

const Content = styled(Select.Content, {
  background: "#fff",
  boxShadow: "0px 8px 20px rgba(0, 0, 0, 0.1)",
  variants: {
    isPlaceholder: {
      true: {
        maxWidth: "95%",
      },
    },
  },
});

const Viewport = styled(Select.Viewport, {});

const Trigger = styled(Select.Trigger, {
  display: "flex",
  justifyContent: "space-between",
  fontSize: "$s",
  lineHeight: `$s`,
  width: "100%",
  padding: "$inputPaddingY $inputPaddingX",
  $$labelFontSize: "$fontSizes$xs",
  $$labelLineHeight: "$lineHeights$xs",
  background: "#FFFFFF",
  boxShadow: "inset 0 0 0 1px $colors$inputBorderColor",
  borderRadius: "$fieldBr",
  position: "relative",
  boxSizing: "border-box",
  color: "$black",
  outline: "none",

  "&:focus": {
    boxShadow: "inset 0 0 0 1px $colors$primary",
  },

  variants: {
    isPlaceholder: {
      true: {
        color: "$grey300",
      },
    },
    isError: {
      true: {
        boxShadow: "inset 0 0 0 1px $colors$red200",
        "&:focus": {
          boxShadow: "inset 0 0 0 1px $colors$red200",
        },
      },
    },
  },
});

const Value = styled(Select.Value, {});
const SelectItemText = styled(Select.ItemText, {});

const SelectItem = styled(Select.SelectItem, {
  all: "unset",
  fontSize: "$s",
  lineHeight: `$s`,
  color: "black",
  borderRadius: "2px",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  padding: "8px 16px",
  position: "relative",
  userSelect: "none",
  cursor: "pointer",
  background: "white",

  "&[data-disabled]": {
    color: "green",
    pointerEvents: "none",
  },

  '&[data-state="checked"]': {
    color: "$primary",
  },

  "&:focus": {
    background: "$grey25",
  },

  "&:hover": {
    background: "$grey25",
  },

  variants: {
    isPlaceholder: {
      true: {
        height: "0",
        padding: "0",
      },
    },
  },
});

// const scrollButtonStyles = {
//   display: 'flex',
//   alignItems: 'center',
//   justifyContent: 'center',
//   height: 25,
//   backgroundColor: 'white',
//   color: 'grey300',
//   cursor: 'default',
// };

// const StyledScrollUpButton = styled(Select.ScrollUpButton, scrollButtonStyles);
// const StyledScrollDownButton = styled(Select.ScrollDownButton, scrollButtonStyles);
const SelectedItemIndicator = styled(Select.ItemIndicator, {});

export type SelectProps = {
  list: SelectOption[];
  name: string;
  placeholder?: string;
  defaultValue?: string;
  isError?: boolean;
  onOpenChange?: (open: boolean) => void;
};

export const SelectElement: React.FC<SelectProps> = ({
  list,
  name,
  defaultValue,
  placeholder,
  isError,
  onOpenChange,
}) => {
  const selectEl = useRef<HTMLSelectElement>(null);
  const [value, setValue] = useState("");
  const isValueEmpty = !defaultValue && value === "";

  useEffect(() => {
    if (defaultValue && selectEl.current)
      setNativeSelectValue(selectEl.current, defaultValue);
  }, [defaultValue]);

  return (
    <Select.Root
      // value={value}
      onOpenChange={(open) => {
        if (onOpenChange) onOpenChange(open);
      }}
      defaultValue={defaultValue}
      onValueChange={(value) => {
        if (selectEl?.current) {
          setNativeSelectValue(selectEl.current, value);
          setValue(value);
        }
      }}
    >
      <VisuallyHidden>
        <select name={name} ref={selectEl} id={name}>
          <option value="" />
          {list.map((item) => (
            <option key={item.id} value={item.id}>
              {item.title}
            </option>
          ))}
        </select>
      </VisuallyHidden>
      <Trigger isPlaceholder={isValueEmpty} isError={isError}>
        <Value />
        <Select.Icon>
          <ChevronDownIcon />
        </Select.Icon>
      </Trigger>
      <Content isPlaceholder={isValueEmpty}>
        {/* <StyledScrollUpButton>
          <ChevronUpIcon />
        </StyledScrollUpButton> */}
        <Viewport>
          <SelectItem value="" isPlaceholder>
            <SelectItemText>{placeholder}</SelectItemText>
          </SelectItem>
          {list.map((item) => (
            <SelectItem value={item.id} key={item.id}>
              <SelectItemText>{item.title}</SelectItemText>
              <SelectedItemIndicator>
                <CheckIcon />
              </SelectedItemIndicator>
            </SelectItem>
          ))}
        </Viewport>
        {/* <StyledScrollDownButton>
          <ChevronDownIcon />
        </StyledScrollDownButton> */}
      </Content>
    </Select.Root>
  );
};
