import React from "react";
import IdentityContent from "@/components/identityContent";
import { Button } from "@/uikit";

interface IOutdated {
  onSubmit: () => void;
}

const Outdated: React.FC<IOutdated> = ({ onSubmit }) => {
  return (
    <IdentityContent
      title="Восстановление пароля"
      subTitle="Ссылка устарела, попробуйте еще раз выполнить восстановление пароля"
      isError={true}
    >
      <Button kind="primary" size="small" isBlock={true} onClick={onSubmit}>
        Понятно
      </Button>
    </IdentityContent>
  );
};

export default Outdated;
