import { FC } from "react";
import { useRegistrationHandler } from "@/ory/hooks";
import RegistrationForm from "@/components/registration/registrationForm";
import RegistrationConfirm from "@/components/registration/registrationConfirm";
import { useGlobalState } from "@/contexts/GlobalContext";
import { useNavigate } from "react-router-dom";

const Registration: FC = () => {
  const [globalState] = useGlobalState();
  const navigate = useNavigate();
  const { token, isPending, isEmailSent, error, setError, handleRegistration } =
    useRegistrationHandler();

  const handleSentSubmit = () => {
    navigate("/login");
  };

  return isEmailSent ? (
    <RegistrationConfirm
      onSubmit={handleSentSubmit}
      email={globalState.identity?.traits?.email}
    />
  ) : (
    <RegistrationForm
      token={token}
      error={error}
      isPending={isPending}
      setError={setError}
      onSubmit={handleRegistration}
    />
  );
};
export { Registration };
