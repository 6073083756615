import { Title } from "@/uikit";
import { styled } from "@stitches/react";

export const StyledModalPasswordWrapper = styled("form", {
  width: "100%",
  display: "grid",
  gridTemplateColumns: "1fr",
  gap: "32px",
});

export const StyledModalPasswordTitle = styled(Title, {
  color: "$black",
});
