import { CSS } from "@stitches/react";
import React from "react";
import { styled } from "stitches.config";

export type TitleProps = {
  children?: React.ReactNode;
  level?: "1" | "2" | "3" | "4";
  color?: "danger";
  css?: CSS;
  className?: string;
};

export const StyledTitle = styled("div", {
  fontSize: "$xl",
  lineHeight: "$xl",
  fontWeight: "$bold",

  // '@l': {
  //   fontSize: '$xxxl',
  //   lineHeight: `$xxxl`,
  // },

  variants: {
    level: {
      "1": {
        fontSize: "$xxl",
        lineHeight: "$xxl",

        "@l": {
          fontSize: `$xxxl`,
          lineHeight: `$xxxl`,
        },
      },
      "2": {
        fontSize: "$l",
        lineHeight: "$l",

        // '@l': {
        //   fontSize: `$xxl`,
        //   lineHeight: `$xxl`,
        // },
      },
      "3": {
        fontSize: "$m",
        lineHeight: "$s",

        // '@l': {
        //   fontSize: `$xl`,
        //   lineHeight: `$xl`,
        // },
      },
      "4": {
        fontSize: "$s",
        lineHeight: "$s",

        // '@l': {
        //   fontSize: `$l`,
        //   lineHeight: `$l`,
        // },
      },
    },
    color: {
      danger: {
        color: "$red200",
      },
    },
  },
});

export const Title: React.FC<TitleProps> = ({
  children,
  level,
  css,
  className,
}) => {
  return (
    <StyledTitle level={level} className={className} css={css}>
      {children}
    </StyledTitle>
  );
};

export const DangerTitle = styled(Title, {
  color: "$red200",
});
