import Modal from "@/components/modal";
import { Button, Input, InputProps } from "@/uikit";
import React, { useState } from "react";
import {
  StyledModalPasswordTitle,
  StyledModalPasswordWrapper,
} from "./index.style";
import { useForm } from "react-hook-form";
import { passwordValidationRule } from "@/helpers/consts";
import { Controller } from "@/components/Controller";
import { INodeError } from "@/helpers/interfaces";
import { getError } from "@/ory/helpers";

export type ModalPasswordProps = {
  isActive: boolean;
  isPending: boolean;
  error: string | INodeError;
  setError: (value: string) => void;
  onClose: () => void;
  onSubmit: (pass: string) => void;
};

const ModalPassword: React.FC<ModalPasswordProps> = (props) => {
  const { isActive, isPending, error, setError, onClose, onSubmit } = props;
  const [newPassword, setNewPassword] = useState("");
  const [passwordConfirmation, setPasswordConfirmation] = useState("");

  const {
    formState: { errors },
    handleSubmit,
    control,
    reset,
  } = useForm({
    mode: "onBlur",
    defaultValues: {
      newPassword,
      passwordConfirmation,
    },
  });

  const handleChange = (handler: (value: string) => void, value: string) => {
    setError("");
    handler(value);
  };

  const handleClose = () => {
    setNewPassword("");
    setPasswordConfirmation("");
    reset();
    onClose();
  };

  return (
    <Modal isOpen={isActive} onClose={handleClose}>
      <StyledModalPasswordWrapper
        onSubmit={handleSubmit(() => onSubmit(newPassword))}
      >
        <StyledModalPasswordTitle level="3">
          Изменение пароля
        </StyledModalPasswordTitle>
        <Controller<InputProps>
          value={newPassword}
          name="newPassword"
          placeholder="Введите новый пароль*"
          type="password"
          rules={passwordValidationRule}
          error={getError(error, "password") || errors?.newPassword?.message}
          control={control}
          isBlock={true}
          component={(inputProps: InputProps) => <Input {...inputProps} />}
          onChange={(value: string) => handleChange(setNewPassword, value)}
        />
        <Controller<InputProps>
          name="passwordConfirmation"
          error={errors?.passwordConfirmation?.message}
          control={control}
          component={(inputProps: InputProps) => <Input {...inputProps} />}
          rules={{
            required: "Это поле обязательное",
            validate: (value: string) => {
              return value === newPassword || "Пароль не совпадает";
            },
          }}
          isError={!!getError(error, "password")}
          value={passwordConfirmation}
          placeholder="Подтвердите пароль*"
          type="password"
          isBlock={true}
          onChange={(value: string) =>
            handleChange(setPasswordConfirmation, value)
          }
        />
        <Button type="submit" kind="primary" size="small" isLoading={isPending}>
          Изменить
        </Button>
      </StyledModalPasswordWrapper>
    </Modal>
  );
};

export default ModalPassword;
