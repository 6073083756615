import { useLogoutHandler } from "@/ory/hooks"
import { useEffect } from "react";

export const Logout = () => {
  const { handleLogout } = useLogoutHandler();
  useEffect(() => {
    handleLogout()
  }, [])

  return null;
}