import * as RadioGroupPrimitive from "@radix-ui/react-radio-group";
import React from "react";
import { styled } from "stitches.config";

const StyledRadioGroup = styled(RadioGroupPrimitive.Root, {
  display: "flex",
  gap: "16px",
});

const StyledRadio = styled(RadioGroupPrimitive.Item, {
  all: "unset",
  backgroundColor: "#eaeeed",
  width: "24px",
  height: "24px",
  borderRadius: "100%",
  "&:hover": { backgroundColor: "#beccc9" },
  // '&:focus': { boxShadow: `0 0 0 2px black` },
});

const StyledIndicator = styled(RadioGroupPrimitive.Indicator, {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  width: "100%",
  height: "100%",
  position: "relative",

  "&::after": {
    content: '""',
    display: "block",
    width: "10px",
    height: "10px",
    borderRadius: "50%",
    backgroundColor: "#29554A",
  },
});

const Flex = styled("div", {
  display: "flex",
  alignItems: "center",
  cursor: "pointer",
});

const Label = styled("label", {
  color: "$black",
  fontSize: "$s",
  lineHeight: "$s",
  userSelect: "none",
  paddingLeft: "8px",
  cursor: "pointer",
});

export const RadioGroup = StyledRadioGroup;
export const RadioGroupIndicator = StyledIndicator;

export type RadioGroupRadio = RadioGroupPrimitive.RadioGroupItemProps & {
  name: string;
  label?: string;
};

export const RadioGroupRadio: React.FC<RadioGroupRadio> = ({
  name,
  label,
  ...props
}) => {
  return (
    <Flex>
      <StyledRadio id={name} {...props}>
        <RadioGroupIndicator />
      </StyledRadio>
      <Label htmlFor={name}>{label}</Label>
    </Flex>
  );
};
