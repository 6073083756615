import { Cross2Icon } from "@radix-ui/react-icons";
import React from "react";
import {
  StyledModalBlock,
  StyledModalClose,
  StyledModalContainer,
  StyledModalWrapper,
} from "./index.style";

export type ModalProps = {
  children: React.ReactNode;
  isOpen: boolean;
  onClose: (active: boolean | ((prevActive: boolean) => boolean)) => void;
};

const Modal: React.FC<ModalProps> = (props) => {
  const { children, isOpen, onClose } = props;

  const closeModalHandler = () => {
    onClose(false);
  };

  return (
    <>
      {isOpen && (
        <StyledModalContainer>
          <StyledModalWrapper onClick={closeModalHandler}>
            <StyledModalBlock onClick={(e) => e.stopPropagation()}>
              <StyledModalClose onClick={closeModalHandler}>
                <Cross2Icon />
              </StyledModalClose>
              {children}
            </StyledModalBlock>
          </StyledModalWrapper>
        </StyledModalContainer>
      )}
    </>
  );
};

export default Modal;
