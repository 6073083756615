import { styled } from "@stitches/react";

export const StyledModalContainer = styled("div", {
  background: "$black-70",
  position: "fixed",
  top: 0,
  left: 0,
  width: "100%",
  height: "100vh",
  overflowX: "hidden",
  overflowY: "auto",
  outline: 0,
  zIndex: 10,
  alignItems: "center",
  justifyContent: "center",
});

export const StyledModalWrapper = styled("div", {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  padding: "50px 0",
  height: "100vh",
});

export const StyledModalBlock = styled("div", {
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  padding: "24px",
  width: "468px",
  background: "$white",
  borderRadius: "8px",
  position: "relative",
});

export const StyledModalClose = styled("div", {
  position: "absolute",
  top: "24px",
  right: "24px",
  cursor: "pointer",
  "& svg": {
    width: "24px",
    height: "24px",
  },
});
