import React, { useEffect } from "react";
import {
  Route,
  Routes,
  createSearchParams,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { useGlobalState } from "@/contexts/GlobalContext";
import { isLoginChallenge, useLoginChallenge, useSession } from "@/ory/hooks";
import { Login } from "@/pages/login";
import { Logout } from "@/pages/logout";
import { Registration } from "@/pages/registration";
import Consent from "@/pages/consent";
import Profile from "@/pages/profile";
import Recovery from "@/pages/recovery";
import Verification from "@/pages/verification";
import Reset from "@/pages/reset";

const UNPROTECTED_ROUTES = [
  "/login",
  "/registration",
  "/verification",
  "/reset",
  "/recovery",
];

const NO_AUTH_ROUTES = [
  "/login",
  "/registration",
  "/verification",
  "/recovery",
];

const RouterProvider: React.FC = () => {
  const [globalState] = useGlobalState();
  const isLoginChallengeRuns = isLoginChallenge();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { handleGetSession, isLoading } = useSession();
  const { challenge } = useLoginChallenge();
  const [searchParams] = useSearchParams();

  useEffect(() => {
    handleGetSession();
  }, []);

  useEffect(() => {
    if (isLoading || (isLoginChallengeRuns && pathname === "/login")) return;

    if (!globalState.identity && !UNPROTECTED_ROUTES.includes(pathname)) {
      navigate({
        pathname: "/login",
        search: `${createSearchParams(searchParams)}`,
      });
    } else if (globalState.identity && NO_AUTH_ROUTES.includes(pathname)) {
      navigate({
        pathname: "/",
        search: `${createSearchParams(searchParams)}`,
      });
    }
  }, [pathname, globalState, isLoading]);

  return (
    <Routes>
      <Route index={true} element={<Profile />} />
      <Route path="/consent" element={<Consent />} />
      <Route path="/login" element={<Login />} />
      <Route path="/logout" element={<Logout />} />
      <Route path="/recovery" element={<Recovery />} />
      <Route path="/registration" element={<Registration />} />
      <Route path="/reset" element={<Reset />} />
      <Route path="/verification" element={<Verification />} />
    </Routes>
  );
};

export default RouterProvider;
