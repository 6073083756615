import { Title } from "@/uikit";
import { styled } from "@stitches/react";

export const StyledModalEmailWrapper = styled("form", {
  width: "100%",
  display: "grid",
  gridTemplateColumns: "1fr",
  gap: "32px",
});

export const StyledModalEmailTitle = styled(Title, {
  color: "$black",
});
