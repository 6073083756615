import IdentityContent from "@/components/identityContent";
import React, { useState } from "react";
import { StyledRecoveryButton, StyledRecoveryWrapper } from "./index.style";
import { useRecoveryHandler } from "@/ory/hooks";
import { useForm } from "react-hook-form";
import { Input, InputProps } from "@/uikit";
import { emailValidationRule } from "@/helpers/consts";
import { Controller } from "@/components/Controller";
import { useNavigate } from "react-router";
import RecoveryPassword from "@/components/recovery/recoveryPassword";

const Recovery: React.FC = () => {
  const [email, setEmail] = useState("");
  const { recoveryPassword, isRecoverySent, isPending } = useRecoveryHandler();
  const navigate = useNavigate();

  const {
    formState: { errors },
    handleSubmit,
    control,
  } = useForm({
    mode: "onBlur",
    defaultValues: {
      email,
    },
  });

  const handleRecovery = async () => {
    await recoveryPassword(email);
  };

  const handleSubmitRecoverySent = () => {
    navigate("/login");
  };

  return isRecoverySent ? (
    <RecoveryPassword email={email} onSubmit={handleSubmitRecoverySent} />
  ) : (
    <IdentityContent
      title="Восстановление пароля"
      subTitle="Введите электронную почту, указанную при регистрации. Мы вышлем Вам ссылку для восстановления пароля."
    >
      <StyledRecoveryWrapper onSubmit={handleSubmit(handleRecovery)}>
        <Controller<InputProps>
          value={email}
          name={"email"}
          rules={emailValidationRule}
          placeholder={"Введите email*"}
          type="text"
          error={errors?.email?.message}
          control={control}
          isBlock={true}
          component={(inputProps: InputProps) => <Input {...inputProps} />}
          onChange={(value: string) => setEmail(value.toLowerCase())}
        />
        <StyledRecoveryButton
          type="submit"
          isLoading={isPending}
          kind="primary"
          size="small"
        >
          Восстановить пароль
        </StyledRecoveryButton>
      </StyledRecoveryWrapper>
    </IdentityContent>
  );
};

export default Recovery;
