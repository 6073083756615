import Modal from "@/components/modal";
import { Button, Input, InputProps } from "@/uikit";
import React, { useEffect, useState } from "react";
import { StyledModalEmailTitle, StyledModalEmailWrapper } from "./index.style";
import { emailValidationRule } from "@/helpers/consts";
import { Controller } from "@/components/Controller";
import { useForm } from "react-hook-form";
import { useGlobalState } from "@/contexts/GlobalContext";
import { INodeError } from "@/helpers/interfaces";

export type ModalEmailProps = {
  isActive: boolean;
  isPending: boolean;
  error: string | INodeError;
  setError: (value: string) => void;
  onSubmit: (email: string) => void;
  onClose: () => void;
};

const ModalEmail: React.FC<ModalEmailProps> = (props) => {
  const { isActive, isPending, setError, onClose, onSubmit } = props;
  const [globalState] = useGlobalState();
  const [email, setEmail] = useState("");
  const [newEmail, setNewEmail] = useState("");

  const {
    formState: { errors },
    handleSubmit,
    control,
    reset,
  } = useForm({
    mode: "onBlur",
    defaultValues: {
      email,
      newEmail,
    },
  });

  const handleChange = (handler: (value: string) => void, value: string) => {
    setError("");
    handler(value);
  };

  const handleClose = () => {
    setEmail("");
    setNewEmail("");
    reset();
    onClose();
  };

  return (
    <Modal isOpen={isActive} onClose={handleClose}>
      <StyledModalEmailWrapper
        onSubmit={handleSubmit(() => onSubmit(newEmail))}
      >
        <StyledModalEmailTitle level="3">Изменение Email</StyledModalEmailTitle>
        <Controller<InputProps>
          value={email}
          name="email"
          rules={{
            required: "Это поле обязательное",
            validate: (value: string) => {
              const isValid = globalState.identity?.traits?.email === value;
              const myEmailRegex = /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/;
              return myEmailRegex.test(value)
                ? isValid || "Пароль не совпадает с текущим"
                : "Введен неверный email";
            },
          }}
          placeholder="Введите текущею почту"
          type="text"
          error={errors?.email?.message}
          control={control}
          isBlock={true}
          component={(inputProps: InputProps) => <Input {...inputProps} />}
          onChange={(value: string) => handleChange(setEmail, value)}
        />
        <Controller<InputProps>
          value={newEmail}
          name="newEmail"
          rules={emailValidationRule}
          placeholder="Введите новую почту"
          type="text"
          error={errors?.newEmail?.message}
          control={control}
          isBlock={true}
          component={(inputProps: InputProps) => <Input {...inputProps} />}
          onChange={(value: string) => handleChange(setNewEmail, value)}
        />
        <Button
          isLoading={isPending}
          kind="primary"
          size="small"
          type="submit"
          isBlock={false}
        >
          Изменить
        </Button>
      </StyledModalEmailWrapper>
    </Modal>
  );
};

export default ModalEmail;
