import { Button, Input } from "@/uikit";
import { styled } from "stitches.config";

export const StyledRecoveryWrapper = styled("form", {
  width: "100%",
  display: "flex",
  alignItems: "flex-start",
  flexDirection: "column",
  justifyContent: "flex-start",
});

export const StyledRecoveryInput = styled(Input, {
  marginTop: "32px",
});

export const StyledRecoveryButton = styled(Button, {
  marginTop: "24px",
  width: "100%",
});
