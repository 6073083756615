import { ChevronDownIcon } from "@radix-ui/react-icons";
import { Button } from "../button";
import { styled } from "stitches.config";

const StyledExpandButton = styled(Button, {
  variants: {
    open: {
      true: {
        ["& svg"]: {
          transform: "rotate(180deg)",
        },
      },
    },
  },
});

export const ExpandButton: React.FC<{ open: boolean; onClick: () => void }> = ({
  open,
  onClick,
}) => (
  <StyledExpandButton kind="ghost" size="small" open={open} onClick={onClick}>
    <ChevronDownIcon fill="red" />
  </StyledExpandButton>
);

ExpandButton.displayName = "ExpandButton";
