import React from "react";
import IdentityContent from "@/components/identityContent";
import {
  StyledLoginInputItem,
  StyledLoginInputList,
  StyledLoginLinkTab,
  StyledLoginLinkTabs,
  StyledLoginLoginButton,
  StyledLoginResetFormLink,
  StyledLoginFormWrapper,
} from "./index.style";
import { Link } from "react-router-dom";
import { Button, ButtonLink, Input, InputProps } from "@/uikit";

import { UpdateLoginFlowWithPasswordMethod } from "@ory/kratos-client/api";
import { INodeError } from "@/helpers/interfaces";
import { getError } from "@/ory/helpers";
import { Controller } from "@/components/Controller";
import {
  emailValidationRule,
  enterPasswordValidationRule,
} from "@/helpers/consts";
import { useForm } from "react-hook-form";

interface ILoginForm {
  data: UpdateLoginFlowWithPasswordMethod;
  isPending?: boolean;
  error?: string | INodeError;
  onSubmit: () => void;
  onDataChange: (
    name: keyof UpdateLoginFlowWithPasswordMethod,
    value: string
  ) => void;
}

const LoginForm: React.FC<ILoginForm> = (props) => {
  const { data, error, isPending, onSubmit, onDataChange } = props;
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({ mode: "onBlur", defaultValues: { ...data } });

  return (
    <IdentityContent title="Вход в личный кабинет">
      <StyledLoginLinkTabs>
        <StyledLoginLinkTab>Новый пользователь?</StyledLoginLinkTab>
        <ButtonLink href="/registration">Создать аккаунт</ButtonLink>
      </StyledLoginLinkTabs>
      <StyledLoginFormWrapper onSubmit={handleSubmit(onSubmit)}>
        <StyledLoginInputList>
          <StyledLoginInputItem>
            <Controller<InputProps>
              value={data.identifier}
              name={"identifier"}
              rules={emailValidationRule}
              placeholder={"Введите email*"}
              type="text"
              error={
                getError(error, "identifier") || errors?.identifier?.message
              }
              control={control}
              isBlock={true}
              autoComplete="email"
              component={(inputProps: InputProps) => <Input {...inputProps} />}
              onChange={(value: string) =>
                onDataChange("identifier", value.toLowerCase())
              }
            />
          </StyledLoginInputItem>
          <StyledLoginInputItem>
            <Controller<InputProps>
              value={data.password}
              name={"password"}
              placeholder={"Введите пароль*"}
              type={"password"}
              autoComplete="current-password"
              rules={enterPasswordValidationRule}
              error={getError(error, "password") || errors?.password?.message}
              control={control}
              isBlock={true}
              component={(inputProps: InputProps) => <Input {...inputProps} />}
              onChange={(value: string) => onDataChange("password", value)}
            />
          </StyledLoginInputItem>
        </StyledLoginInputList>
        <StyledLoginLoginButton>
          <Button
            kind="primary"
            className="authLoginButton"
            size="small"
            isLoading={isPending}
            isBlock={true}
            type="submit"
          >
            Войти
          </Button>
        </StyledLoginLoginButton>
      </StyledLoginFormWrapper>
      <Link to={"/recovery"}>
        <StyledLoginResetFormLink>Забыли пароль?</StyledLoginResetFormLink>
      </Link>
    </IdentityContent>
  );
};

export default LoginForm;
