import { AxiosError } from "@ory/hydra-client/node_modules/axios";

const waitPause = (time: number) => new Promise((res) => setTimeout(res, time));

export const callWithRetry = async (
  request: () => Promise<any>,
  depth = 0,
  condition?: (e: AxiosError<any>) => boolean
): Promise<any> => {
  try {
    return await request();
  } catch (e) {
    if (depth > 4 || (condition && !condition(e as AxiosError))) {
      throw e;
    }
    await waitPause(depth * 100);

    return callWithRetry(request, depth + 1, condition);
  }
};
