import { Title, Text } from "@/uikit";
import { styled } from "@stitches/react";

export const StyledModalEmailConfirmWrapper = styled("div", {
  width: "100%",
  display: "grid",
  gridTemplateColumns: "1fr",
  gap: "32px",
});

export const StyledModalEmailConfirmTitle = styled(Title, {
  color: "$black",
});

export const StyledModalEmailConfirmSubTitle = styled(Text, {
  width: "100%",
});

export const StyledModalEmailConfirmSubTitleLink = styled("span", {
  width: "100%",
  fontStyle: "$normal",
  fontWeight: "$bold",
  fontSize: "$s",
  lineHeight: "$l",
  color: "$black",
});
