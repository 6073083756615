import { DialogTitleProps } from "@radix-ui/react-dialog";
import React from "react";
import * as DialogPrimitive from "@radix-ui/react-dialog";
import { Cross1Icon } from "@radix-ui/react-icons";
import { Button } from "../buttons/button";
import { styled, keyframes } from "stitches.config";

export type DialogProps = {
  className?: string;
  children?: React.ReactNode;
};

const overlayShow = keyframes({
  "0%": { opacity: 0 },
  "100%": { opacity: 0.6 },
});

const contentShow = keyframes({
  "0%": { opacity: 0, transform: "translate(-50%, -48%) scale(.96)" },
  "100%": { opacity: 1, transform: "translate(-50%, -50%) scale(1)" },
});

const StyledOverlay = styled(DialogPrimitive.Overlay, {
  background: "$grey300",
  position: "fixed",
  inset: 0,
  zIndex: "2",

  "@media (prefers-reduced-motion: no-preference)": {
    animation: `${overlayShow} 150ms cubic-bezier(0.16, 1, 0.3, 1) forwards`,
  },
});

const StyledContent = styled(DialogPrimitive.Content, {
  backgroundColor: "white",
  borderRadius: "$mainBr",
  position: "fixed",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  zIndex: "3",

  width: "90vw",
  maxWidth: "450px",
  maxHeight: "85vh",
  padding: "26px 24px",
  overflow: "auto",

  "@media (prefers-reduced-motion: no-preference)": {
    animation: `${contentShow} 150ms cubic-bezier(0.16, 1, 0.3, 1) forwards`,
  },
  "&:focus": { outline: "none" },
});

const StyledDescription = styled(DialogPrimitive.Description, {
  margin: 0,
});

const StyledDialogTitleBox = styled("div", {
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  marginBottom: "26px",
});

const StyledCloseButton = styled(Button, {
  "&&&": {
    padding: 0,
    height: "100%",
  },
});

export const Dialog = DialogPrimitive.Root;
export const DialogTrigger = DialogPrimitive.Trigger;
export const DialogTitle = DialogPrimitive.Title;
export const DialogPortal = DialogPrimitive.Portal;
export const DialogDescription = StyledDescription;
export const DialogClose: React.FC = () => (
  <DialogPrimitive.Close asChild>
    <StyledCloseButton kind="ghost" size="small">
      <Cross1Icon />
    </StyledCloseButton>
  </DialogPrimitive.Close>
);

export const DialogTitleWithClose: React.FC<DialogTitleProps> = ({
  children,
  ...props
}) => (
  <StyledDialogTitleBox>
    <DialogPrimitive.Title {...props}>{children}</DialogPrimitive.Title>
    <DialogClose />
  </StyledDialogTitleBox>
);

export const DialogContent: React.FC<DialogProps> = React.memo(
  React.forwardRef<HTMLDivElement, DialogProps>(
    ({ children, ...props }, forwardedRef) => {
      return (
        <DialogPrimitive.Portal>
          <StyledOverlay />
          <StyledContent {...props} ref={forwardedRef}>
            {children}
          </StyledContent>
        </DialogPrimitive.Portal>
      );
    }
  )
);

DialogContent.displayName = "DialogContent";
