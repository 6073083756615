import React, { useEffect, useState } from "react";
import { UpdateLoginFlowWithPasswordMethod } from "@ory/kratos-client/api";
import { useLoginHandler } from "@/ory/hooks";
import LoginForm from "@/components/login/loginForm";

const Login: React.FC = () => {
  const [data, setData] = useState<UpdateLoginFlowWithPasswordMethod>({
    csrf_token: "",
    identifier: "",
    method: "password",
    password: "",
  });
  const { token, isPending, error, setError, handleLogin } = useLoginHandler();

  useEffect(() => {
    if (token)
      setData((prevState) => ({
        ...prevState,
        csrf_token: token,
      }));
  }, [token]);

  const handleDataChange = (
    name: keyof UpdateLoginFlowWithPasswordMethod,
    value: string
  ) => {
    setError("");
    setData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  return (
    <LoginForm
      data={data}
      isPending={isPending}
      error={error}
      onDataChange={handleDataChange}
      onSubmit={() => handleLogin(data)}
    />
  );
};

export { Login };
