import { RefObject } from "react";
import {
  Control,
  ValidationRule,
  Controller as HookController,
} from "react-hook-form";

type IController<T> = T & {
  control: Control;
  rules?: {
    [key: string]: ValidationRule;
  };
  name: string;
  ref?: RefObject<any>;
  component: (props: T) => JSX.Element;
};

const Controller = <T,>(props: IController<T & any>): JSX.Element => {
  const { control, rules, name, component, value: outerValue, ...rest } = props;

  return (
    <HookController
      control={control}
      rules={rules}
      name={name}
      render={({ field: { onChange, onBlur, value } }) =>
        component({
          ...rest,
          onChange: (eventValue: string | boolean | number) => {
            onChange(eventValue);
            if (rest.onChange) rest.onChange(eventValue);
          },
          onBlur: (eventValue: string | boolean | number) => {
            onBlur();
            if (rest.onBlur) rest.onBlur(eventValue);
          },
          value: outerValue !== value ? outerValue : value,
        })
      }
    />
  );
};

export { Controller };
