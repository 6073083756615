import { createContext, useContext, Dispatch, SetStateAction } from "react";
import { Identity } from "@ory/kratos-client";

export interface IGlobalState {
  identity: Identity | null;
  isVerificationRequired: boolean;
}

const DEFAULT_GLOBAL_STATE = {
  identity: null,
  isVerificationRequired: false,
};

const ContextGlobalState = createContext<
  [IGlobalState, Dispatch<SetStateAction<IGlobalState>>]
>([DEFAULT_GLOBAL_STATE, () => ({})]);

const useGlobalState = () => useContext(ContextGlobalState);

export { DEFAULT_GLOBAL_STATE, ContextGlobalState, useGlobalState };
