import React from "react";
import {
  StyledRecoveryPasswordWrapper,
  StyledRecoveryPasswordText,
  StyledRecoveryPasswordTextLink,
  StyledRecoveryPasswordButton,
} from "./index.style";
import IdentityContent from "@/components/identityContent";

interface IRecoveryPassword {
  email: string;
  onSubmit: () => void;
}

const RecoveryPassword: React.FC<IRecoveryPassword> = ({ onSubmit, email }) => {
  return (
    <IdentityContent title={"Восстановление пароля"}>
      <StyledRecoveryPasswordWrapper>
        <StyledRecoveryPasswordText>
          Мы отправили письмо с ссылкой для восстановления пароля на email{" "}
          <StyledRecoveryPasswordTextLink>
            {email}
          </StyledRecoveryPasswordTextLink>
        </StyledRecoveryPasswordText>
        <StyledRecoveryPasswordButton
          kind="primary"
          size="small"
          isBlock={true}
          onClick={onSubmit}
        >
          Понятно
        </StyledRecoveryPasswordButton>
      </StyledRecoveryPasswordWrapper>
    </IdentityContent>
  );
};

export default RecoveryPassword;
