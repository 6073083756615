import React from "react";
import { Link } from "react-router-dom";
import { styled } from "stitches.config";

export type ButtonLinkProps = {
  children?: React.ReactNode;
  href?: string;
  isDisabled?: boolean;
  isLeftIcon?: boolean;
  isRightIcon?: boolean;
  icon?: React.ReactNode;
  target?: string;
  className?: string;
  onClick?: () => void;
};

export const StyledButtonLinkContainer = styled("div", {
  display: "flex",
  alignItems: "center",
});

export const StyledButtonLink = styled("button", {
  width: "max-content",
  fontFamily: "$fontDisplay",
  fontStyle: "normal",
  fontSize: "$s",
  lineHeight: "$l",
  fontWeight: "$regular",
  color: "$black",
  transition: "all 0.5s ease",

  variants: {
    isDisabled: {
      true: {
        color: "$grey200",
      },
    },
  },

  "&:disabled": {
    color: "$grey200",
  },

  "&:hover": {
    color: "$green200",
  },
});

export const StyledButtonLinkIcon = styled("div", {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  variants: {},
});

export const StyledButtonLinkLeftIcon = styled(StyledButtonLinkIcon, {
  marginRight: "8px",
});

export const StyledButtonLinkRightIcon = styled(StyledButtonLinkIcon, {
  marginLeft: "8px",
});

export const ButtonLink: React.FC<ButtonLinkProps> = React.memo(
  ({
    children,
    href,
    isDisabled,
    icon,
    className,
    isLeftIcon,
    isRightIcon,
    target,
    onClick,
    ...props
  }) => {
    return (
      <StyledButtonLinkContainer>
        {icon && isLeftIcon ? (
          <StyledButtonLinkLeftIcon>{icon}</StyledButtonLinkLeftIcon>
        ) : (
          <></>
        )}
        {href ? (
          <StyledButtonLink
            as={Link}
            isDisabled={isDisabled}
            onClick={onClick}
            to={href as string}
            target={target}
            className={className}
            {...props}
          >
            {children}
          </StyledButtonLink>
        ) : (
          <StyledButtonLink
            isDisabled={isDisabled}
            onClick={onClick}
            className={className}
            {...props}
          >
            {children}
          </StyledButtonLink>
        )}
        {icon && isRightIcon ? (
          <StyledButtonLinkRightIcon>{icon}</StyledButtonLinkRightIcon>
        ) : (
          <></>
        )}
      </StyledButtonLinkContainer>
    );
  }
);

ButtonLink.displayName = "ButtonLink";
