export const BoardIcon = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.53031 1.01507V0H2.53031V1.01507H1.51518C1.23904 1.01507 1.01518 1.23892 1.01518 1.51507V2.53037H0V3.53037H1.01518V5.56062H0V6.56062H1.01518V7.57567C1.01518 7.85182 1.23904 8.07567 1.51518 8.07567H2.53031V9.09095H3.53031V8.07567H5.56062V9.09095H6.56062V8.07567H7.57579C7.85193 8.07567 8.07579 7.85182 8.07579 7.57567V6.56062H9.09089V5.56062H8.07579V3.53037H9.09089V2.53037H8.07579V1.51507C8.07579 1.23892 7.85193 1.01507 7.57579 1.01507H6.56062V0H5.56062V1.01507H3.53031ZM2.01518 7.07567V2.01507H7.07579V7.07567H2.01518Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.53031 11.9242V10.9092H2.53031V11.9242H1.51518C1.23904 11.9242 1.01518 12.1481 1.01518 12.4242V13.4394H0V14.4394H1.01518V16.4697H0V17.4697H1.01518V18.4849C1.01518 18.761 1.23904 18.9849 1.51518 18.9849H2.53031V20.0001H3.53031V18.9849H5.56062V20.0001H6.56062V18.9849H7.57579C7.85193 18.9849 8.07579 18.761 8.07579 18.4849V17.4697H9.09089V16.4697H8.07579V14.4394H9.09089V13.4394H8.07579V12.4242C8.07579 12.1481 7.85193 11.9242 7.57579 11.9242H6.56062V10.9092H5.56062V11.9242H3.53031ZM2.01518 17.9849V12.9242H7.07579V17.9849H2.01518Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.4394 0V1.01507H16.4697V0H17.4697V1.01507H18.4848C18.761 1.01507 18.9848 1.23892 18.9848 1.51507V2.53037H20V3.53037H18.9848V5.56062H20V6.56062H18.9848V7.57567C18.9848 7.85182 18.761 8.07567 18.4848 8.07567H17.4697V9.09095H16.4697V8.07567H14.4394V9.09095H13.4394V8.07567H12.4242C12.1481 8.07567 11.9242 7.85182 11.9242 7.57567V6.56062H10.9091V5.56062H11.9242V3.53037H10.9091V2.53037H11.9242V1.51507C11.9242 1.23892 12.1481 1.01507 12.4242 1.01507H13.4394V0H14.4394ZM12.9242 2.01507V7.07567H17.9848V2.01507H12.9242Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.4394 11.9242V10.9092H13.4394V11.9242H12.4242C12.1481 11.9242 11.9242 12.1481 11.9242 12.4242V13.4394H10.9091V14.4394H11.9242V16.4697H10.9091V17.4697H11.9242V18.4849C11.9242 18.761 12.1481 18.9849 12.4242 18.9849H13.4394V20.0001H14.4394V18.9849H16.4697V20.0001H17.4697V18.9849H18.4848C18.761 18.9849 18.9848 18.761 18.9848 18.4849V17.4697H20V16.4697H18.9848V14.4394H20V13.4394H18.9848V12.4242C18.9848 12.1481 18.761 11.9242 18.4848 11.9242H17.4697V10.9092H16.4697V11.9242H14.4394ZM12.9242 17.9849V12.9242H17.9848V17.9849H12.9242Z"
      fill="currentColor"
    />
  </svg>
);
