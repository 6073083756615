import React from "react";
import IdentityContent from "../../identityContent";
import {
  StyledRegistrationConfirmText,
  StyledRegistrationConfirmBold,
  StyledRegistrationConfirmButton,
} from "./index.style";

interface IRegistrationConfirm {
  email: string;
  onSubmit: () => void;
}

const RegistrationConfirm: React.FC<IRegistrationConfirm> = ({
  email,
  onSubmit,
}) => {
  return (
    <IdentityContent title="Подтверждение электронной почты">
      <StyledRegistrationConfirmText>
        Мы отправили письмо с ссылкой для подтверждения регистрации на email{" "}
        <StyledRegistrationConfirmBold>{email}</StyledRegistrationConfirmBold>
      </StyledRegistrationConfirmText>

      <StyledRegistrationConfirmButton
        kind="primary"
        size="small"
        onClick={onSubmit}
      >
        Понятно
      </StyledRegistrationConfirmButton>
    </IdentityContent>
  );
};

export default RegistrationConfirm;
