import ModalEmail from "@/components/modals/ModalEmail";
import ModalEmailConfirm from "@/components/modals/ModalEmailConfirm";
import ModalPassword from "@/components/modals/ModalPassword";
import ModalPasswordConfirm from "@/components/modals/ModalPasswordConfirm";
import { ArrowLeftIcon } from "@radix-ui/react-icons";
import React, { useState } from "react";
import {
  StyledProfileWrapper,
  StyledProfileContainer,
  StyledProfileBackLink,
  StyledProfileBlock,
  StyledProfileTitle,
  StyledProfileButton,
  StyledProfileInput,
} from "./index.style";
import { useLogoutHandler, useSettingsHandler } from "@/ory/hooks";
import { useGlobalState } from "@/contexts/GlobalContext";

const LINK_BACK = process.env.REACT_APP_CONSOLE_URL;

const Profile: React.FC = () => {
  const [activeModalEmail, setActiveModalEmail] = useState(false);
  const [activeModalPassword, setActiveModalPassword] = useState(false);
  const [activeModalEmailConfirm, setActiveModalEmailConfirm] = useState(false);
  const [activeModalPasswordConfirm, setActiveModalPasswordConfirm] =
    useState(false);
  const [globalState] = useGlobalState();
  const { handleSubmit, isPending, error, setError } = useSettingsHandler();
  const { handleLogout } = useLogoutHandler();

  const handleEmailChange = async (email: string) => {
    try {
      await handleSubmit(email, "profile");
      setActiveModalEmail(false);
      setActiveModalEmailConfirm(true);
    } catch (e) {
      console.log(e);
    }
  };

  const handlePasswordChange = async (pass: string) => {
    try {
      await handleSubmit(pass, "password");
      setActiveModalPassword(false);
      setActiveModalPasswordConfirm(true);
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <StyledProfileWrapper>
      <StyledProfileContainer>
        <a href={LINK_BACK}>
          <StyledProfileBackLink>
            <ArrowLeftIcon />
            Вернуться
          </StyledProfileBackLink>
        </a>
        <StyledProfileTitle>Личный профиль</StyledProfileTitle>
        <StyledProfileBlock>
          <StyledProfileInput
            name="email"
            placeholder="Ваш email"
            value={globalState.identity?.traits?.email}
            isDisabled={true}
          />
          <StyledProfileButton
            kind="tertiary"
            disabled={false}
            size="small"
            onClick={() => setActiveModalPassword(true)}
          >
            Изменить пароль
          </StyledProfileButton>
        </StyledProfileBlock>
      </StyledProfileContainer>
      <ModalEmail
        isActive={activeModalEmail}
        isPending={isPending}
        error={error}
        setError={setError}
        onClose={() => setActiveModalEmail(false)}
        onSubmit={handleEmailChange}
      />
      <ModalPassword
        isActive={activeModalPassword}
        isPending={isPending}
        error={error}
        setError={setError}
        onClose={() => setActiveModalPassword(false)}
        onSubmit={handlePasswordChange}
      />
      <ModalEmailConfirm
        isActive={activeModalEmailConfirm}
        onClose={handleLogout}
      />
      <ModalPasswordConfirm
        isActive={activeModalPasswordConfirm}
        onClose={handleLogout}
      />
    </StyledProfileWrapper>
  );
};

export default Profile;
