import React from "react";
import { Title } from "../../typography";
import { styled } from "stitches.config";
import { VariantProps } from "@stitches/react";

const StyledCard = styled("div", {
  position: "relative",
  overflow: "hidden",
  backgroundColor: "$main",
  borderRadius: "$mainMobileBr",
  px: "$contentBlockMobilePaddingX",
  py: "$contentBlockMobilePaddingY",
  height: "100%",
  width: "100%",
  boxSizing: "border-box",

  "@l": {
    borderRadius: "$mainBr",
    px: "$contentBlockPaddingX",
    py: "$contentBlockPaddingY",
  },
  variants: {
    padding: {
      small: {
        padding: "$contentBlockMobilePadding",
        "@l": {
          padding: "$mainPadding",
        },
      },
    },
  },
});

const StyledCardHead = styled("div", {
  display: "flex",
  alignItems: "center",
  marginBottom: "$contentBlockHeadIndentMobile",

  "@l": {
    marginBottom: "$contentBlockHeadIndent",
  },
});

const StyledCardTitle = styled("div", {
  flex: "1",
});

const CardExtra = styled("div", {});

const CardBody = styled("div", {});

export type CardProps = {
  children?: React.ReactNode;
  title?: React.ReactNode | string;
  padding?: VariantProps<typeof StyledCard>["padding"];
};

export const Card: React.FC<CardProps> = ({ children, title, padding }) => {
  return (
    <StyledCard padding={padding}>
      {title &&
        (React.isValidElement(title) ? (
          title
        ) : (
          <StyledCardHead>
            <StyledCardTitle>
              <Title level="2">{title}</Title>
            </StyledCardTitle>
          </StyledCardHead>
        ))}
      <CardBody>{children}</CardBody>
    </StyledCard>
  );
};
