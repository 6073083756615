import IdentityContent from "@/components/identityContent";
import { Button, Input, InputProps } from "@/uikit";
import React, { useState } from "react";
import { StyledResetWrapper } from "./index.style";
import { Controller } from "@/components/Controller";
import { passwordValidationRule } from "@/helpers/consts";
import { useForm } from "react-hook-form";
import { useLogoutHandler, useSettingsHandler } from "@/ory/hooks";
import ResetPassword from "@/components/reset/resetConfirm";
import { getError } from "@/ory/helpers";
import Outdated from "@/components/reset/outdated";
import { useNavigate } from "react-router-dom";

const Reset: React.FC = () => {
  const [isPasswordChanged, setIsPasswordChanged] = useState(false);
  const [newPassword, setNewPassword] = useState("");
  const [passwordConfirmation, setPasswordConfirmation] = useState("");
  const {
    handleSubmit: onSubmit,
    error,
    setError,
    isPending,
    isOutdated,
  } = useSettingsHandler();
  const { handleLogout } = useLogoutHandler();

  const {
    formState: { errors },
    handleSubmit,
    control,
  } = useForm({
    mode: "onBlur",
    defaultValues: {
      newPassword,
      passwordConfirmation,
    },
  });

  const navigate = useNavigate();

  const handleChange = (handler: (value: string) => void, value: string) => {
    setError("");
    handler(value);
  };

  const handlePasswordChangeSubmit = async () => {
    try {
      await onSubmit(newPassword, "password");
      setIsPasswordChanged(true);
    } catch (e) {
      console.log(e);
    }
  };

  if (isOutdated) {
    return <Outdated onSubmit={() => navigate("/recovery")} />;
  }

  return isPasswordChanged ? (
    <ResetPassword onSubmit={() => handleLogout()} />
  ) : (
    <IdentityContent
      title="Восстановление пароля"
      subTitle="Введите новый пароль"
    >
      <StyledResetWrapper onSubmit={handleSubmit(handlePasswordChangeSubmit)}>
        <Controller<InputProps>
          value={newPassword}
          name="newPassword"
          placeholder="Введите пароль*"
          type="password"
          rules={passwordValidationRule}
          error={getError(error, "password") || errors?.newPassword?.message}
          control={control}
          isBlock={true}
          autoComplete="new-password"
          component={(inputProps: InputProps) => <Input {...inputProps} />}
          onChange={(value: string) => handleChange(setNewPassword, value)}
        />
        <Controller<InputProps>
          name="passwordConfirmation"
          error={errors?.passwordConfirmation?.message}
          isError={!!getError(error, "password")}
          control={control}
          component={(inputProps: InputProps) => <Input {...inputProps} />}
          rules={{
            required: "Это поле обязательное",
            validate: (value: string) => {
              return value === newPassword || "Пароль не совпадает";
            },
          }}
          value={passwordConfirmation}
          placeholder="Подтвердите пароль*"
          type="password"
          isBlock={true}
          autoComplete="new-password"
          onChange={(value: string) =>
            handleChange(setPasswordConfirmation, value)
          }
        />
        <Button
          type="submit"
          kind="primary"
          size="small"
          isLoading={isPending}
          isBlock={true}
        >
          Подтвердить
        </Button>
      </StyledResetWrapper>
    </IdentityContent>
  );
};

export default Reset;
