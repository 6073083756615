import { styled } from "stitches.config";

const gaps = [2, 4, 6, 8, 10, 12, 16, 24, 32] as const;

export type GapVariants = {
  [key in typeof gaps[number]]: Record<typeof gaps[number], string>;
};

export const gapMap = gaps.reduce(
  (acc, gap) => ({
    ...acc,
    [gap]: { gap: `${gap}px` },
  }),
  {} as GapVariants
);

export const FlexContainer = styled("div", {
  display: "flex",
  variants: {
    wrap: {
      true: {
        flexWrap: "wrap",
      },
    },
    stretch: {
      true: {
        width: "100%",
      },
    },
    center: {
      true: {
        justifyContent: "center",
        alignItems: "center",
      },
    },
    flexDirection: {
      column: {
        flexDirection: "column",
      },
    },
    justifyContent: {
      "space-between": {
        justifyContent: "space-between",
      },
      "space-around": {
        justifyContent: "space-around",
      },
      "space-evenly": {
        justifyContent: "space-evenly",
      },
    },
    alignItems: {
      flexStart: {
        alignItems: "flex-start",
      },
      center: {
        alignItems: "center",
      },
      baseline: {
        alignItems: "baseline",
      },
      flexEnd: {
        alignItems: "flex-end",
      },
    },
    gap: {
      small: {
        gap: "$smallGap",
      },
      main: {
        gap: "$mainGap",
      },
      large: {
        gap: "$largeGap",
      },
      ...gapMap,
    },
  },
});
