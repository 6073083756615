import * as SwitchPrimitive from "@radix-ui/react-switch";
import React, { RefObject } from "react";
import { styled } from "stitches.config";

export type SwitchProps = {
  name: string;
  label?: string;
  value?: boolean;
  inputRef?: RefObject<HTMLInputElement>;
  size?: "default" | "small" | "large";
  disabled?: boolean;
  onChange?: (value: boolean) => void;
};

const StyledLabel = styled("label", {
  color: "$black",
  fontSize: "$s",
  lineHeight: "$s",
  userSelect: "none",
});

export const StyledSwitchBox = styled("div", {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  gap: "14px",
  position: "relative",

  background: "#FFFFFF",
  boxSizing: "border-box",

  "&:focus-within": {},

  variants: {
    disabled: {
      true: {
        backgroundColor: "$grey100",
        borderColor: "$grey100",
        color: "$grey300",
      },
    },
  },
});

const StyledSwitch = styled(SwitchPrimitive.Root, {
  all: "unset",
  width: 36,
  height: 20,
  backgroundColor: "$primary",
  borderRadius: "9999px",
  position: "relative",
  WebkitTapHighlightColor: "rgba(0, 0, 0, 0)",
  // '&:focus': { boxShadow: `0 0 0 2px black` },
  '&[data-state="checked"]': { backgroundColor: "$primary" },
});

const StyledThumb = styled(SwitchPrimitive.Thumb, {
  display: "block",
  width: 16,
  height: 16,
  backgroundColor: "$white",
  borderRadius: "9999px",
  transition: "transform 100ms",
  transform: "translateX(2px)",
  willChange: "transform",
  '&[data-state="checked"]': { transform: "translateX(18px)" },
});

export const Switch: React.FC<SwitchProps> = React.memo(
  ({ label, name, value, disabled, onChange }) => {
    return (
      <StyledSwitchBox disabled={disabled}>
        <StyledSwitch checked={value} onCheckedChange={onChange} id={name}>
          <StyledThumb />
        </StyledSwitch>
        {label && <StyledLabel htmlFor={name}>{label}</StyledLabel>}
      </StyledSwitchBox>
    );
  }
);

Switch.displayName = "Switch";
