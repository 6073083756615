import Modal from "@/components/modal";
import { Button } from "@/uikit";
import React from "react";
import {
  StyledModalEmailConfirmSubTitle,
  StyledModalEmailConfirmSubTitleLink,
  StyledModalEmailConfirmTitle,
  StyledModalEmailConfirmWrapper,
} from "./index.style";
import {useGlobalState} from "@/contexts/GlobalContext";

export type ModalEmailConfirmProps = {
  isActive: boolean;
  onClose: () => void;
};

const ModalEmailConfirm: React.FC<ModalEmailConfirmProps> = (props) => {
  const { isActive, onClose } = props;
  const [globalState] = useGlobalState();

  return (
    <Modal isOpen={isActive} onClose={onClose}>
      <StyledModalEmailConfirmWrapper>
        <StyledModalEmailConfirmTitle level="3">
          Изменение Email
        </StyledModalEmailConfirmTitle>
        <StyledModalEmailConfirmSubTitle>
          Необходимо подтверждение. Мы отправили письмо с ссылкой для
          подтверждения на email{" "}
          <StyledModalEmailConfirmSubTitleLink>
            {globalState.identity?.traits?.email}
          </StyledModalEmailConfirmSubTitleLink>
        </StyledModalEmailConfirmSubTitle>
        <Button
          kind="primary"
          size="small"
          onClick={onClose}
          isBlock={false}
        >
          Понятно
        </Button>
      </StyledModalEmailConfirmWrapper>
    </Modal>
  );
};

export default ModalEmailConfirm;
