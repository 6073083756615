import * as icons from "@radix-ui/react-icons";
import * as customIcons from "../../icons";
import type * as Stitches from "@stitches/react";
import React, { ForwardedRef } from "react";
import { styled } from "stitches.config";
import { Loader } from "@/uikit/loader";

const StyledButton = styled("button", {
  border: 0,
  margin: 0,
  cursor: "pointer",
  display: "inline-flex",
  alignItems: "center",
  justifyContent: "center",
  fontSize: "$s",
  lineHeight: "$s",
  fontWeight: "$regular",
  whiteSpace: "nowrap",
  textDecoration: "none",
  width: "max-content",

  "&:disabled": {
    cursor: "default",
  },

  variants: {
    onlyIcon: {
      true: {
        borderRadius: "100%",
      },
    },
    isBlock: {
      true: {
        width: "100%",
      },
    },
    size: {
      default: {
        px: "$btnPaddingX",
        py: "$btnPaddingY",
        gap: "$btnGap",
        borderRadius: "$btnBr",

        "& svg": {
          width: "$btnIconSize",
          height: "$btnIconSize",
        },
      },
      small: {
        px: "$btnSmallPaddingX",
        py: "$btnSmallPaddingY",
        gap: "$btnSmallGap",
        borderRadius: "$btnSmallBr",

        "& svg": {
          width: "$btnSmallIconSize",
          height: "$btnSmallIconSize",
        },
      },
    },

    kind: {
      secondary: {
        backgroundColor: "$btnSecondaryBg",
        color: "$btnSecondaryColor",

        "&:disabled": {
          color: "$btnSecondaryColorDisabled",
          backgroundColor: "$btnSecondaryBgDisabled",
        },

        "&:not([disabled])": {
          "&:hover": {
            backgroundColor: "$btnSecondaryBgHover",
            color: "$btnSecondaryColorHover",
          },
        },
      },
      primary: {
        backgroundColor: "$btnBg",
        color: "$btnColor",

        "&:disabled": {
          color: "$btnColorDisabled",
          backgroundColor: "$btnBgDisabled",
        },

        "&:not([disabled])": {
          "&:hover": {
            backgroundColor: "$btnBgHover",
            color: "$btnColorHover",
          },
        },
      },
      tertiary: {
        backgroundColor: "$btnGhostBg",
        boxShadow: "inset 0 0 0 1px $colors$btnGhostBorderColor",
        color: "$btnGhostColor",

        "&:disabled": {
          color: "$btnGhostColorDisabled",
          boxShadow: "inset 0 0 0 1px $colors$btnGhostBgDisabled",
        },

        "&:not([disabled])": {
          "&:hover": {
            // backgroundColor: '$btnGhostBgHover',
            // color: '$btnGhostColorHover',
          },
        },
      },
      ghost: {
        // backgroundColor: '$btnGhostBg',
        // boxShadow: 'inset 0 0 0 1px $colors$btnGhostBorderColor',
        // color: '$btnGhostColor',
        // '&:focus': { boxShadow: `0 0 0 2px black` },
      },
      link: {
        padding: 0,
      },
      danger: {
        backgroundColor: "$btnDangerBg",
        color: "$btnColor",
      },
    },
  },

  compoundVariants: [
    {
      size: "default",
      onlyIcon: true,
      css: {
        padding: "$btnWithOnlyIconPadding",
      },
    },
    {
      size: "small",
      onlyIcon: true,
      css: {
        padding: "$btnSmallWithOnlyIconPadding",
      },
    },
    {
      kind: "primary",
      onlyIcon: true,
      css: { color: "$black", backgroundColor: "$white" },
    },
  ],

  defaultVariants: {
    size: "default",
    kind: "secondary",
  },
});

const isIcon = (
  child: React.ReactChild | React.ReactFragment | React.ReactPortal
): boolean => {
  return (
    React.isValidElement(child) &&
    [...Object.values(icons), ...Object.values(customIcons)].includes(
      child.type as any
    )
  );
};

type StyledButtonVariants = Stitches.VariantProps<typeof StyledButton>;

export type ButtonProps<T> = Pick<StyledButtonVariants, "size" | "kind"> &
  Omit<React.HTMLProps<HTMLButtonElement>, "size" | "type" | "as"> & {
    type?: "button" | "reset" | "submit";
    className?: string;
    onClick?: () => void;
    as?: React.FC<T> | string;
    isDisabled?: boolean;
    isLoading?: boolean;
    isBlock?: boolean;
  } & T;

const Btn = React.forwardRef(
  <T,>(
    {
      type,
      onClick,
      className,
      children,
      kind,
      size,
      isDisabled,
      isLoading,
      isBlock,
      ...props
    }: React.PropsWithChildren<ButtonProps<T>>,
    ref: ForwardedRef<HTMLButtonElement>
  ) => {
    const content = React.Children.toArray(children);
    const onlyIcon = content.length === 1 && isIcon(content[0]);

    return (
      <StyledButton
        {...props}
        type={type || "button"}
        onClick={isDisabled || isLoading ? undefined : onClick}
        kind={kind}
        size={size}
        disabled={isDisabled}
        onlyIcon={onlyIcon}
        className={className}
        isBlock={isBlock}
        ref={ref}
      >
        {isLoading ? <Loader size={15} /> : content}
      </StyledButton>
    );
  }
);

Btn.displayName = "Button";

export const Button = Btn as <T>(
  props: ButtonProps<T> & { ref?: React.Ref<HTMLButtonElement> }
) => JSX.Element;
