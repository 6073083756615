import { AxiosError } from "@ory/hydra-client/node_modules/axios";
import { NavigateFunction } from "react-router-dom";
import { Dispatch, SetStateAction } from "react";
import { INodeError } from "@/helpers/interfaces";
import { UiContainer } from "@ory/kratos-client";

const switchError = (
  switcher: string | number,
  message?: string
): string | INodeError => {
  switch (switcher) {
    case 4000007:
      return "Данный E-mail уже зарегистрирован";
    case 4000006:
      return "Некорректный E-mail или пароль";
    case 4000005:
      return { node: "password", message: message || "" };
    case 4000034:
      return { node: "password", message: message || "" };
  }

  return "";
};

export function handleFlowError<S>(
  flowType: "login" | "registration" | "settings" | "recovery" | "verification",
  resetFlow: Dispatch<SetStateAction<S | undefined>>,
  setError: Dispatch<SetStateAction<string | INodeError>>,
  navigate?: NavigateFunction
) {
  return async (err: AxiosError<{ ui: UiContainer }>) => {
    console.log("Handling flow error", err);

    if (err.response?.status === 400) {
      if (err.response?.data?.ui?.messages) {
        Object.values(err.response?.data?.ui?.messages)?.forEach((node) => {
          setError(switchError(node.id, node.text));
        });
      }
      if (err.response?.data?.ui?.nodes) {
        err.response?.data?.ui?.nodes?.forEach((node) => {
          node?.messages?.forEach((message) => {
            setError(switchError(message.id, message.text));
          });
        });
      }
      return Promise.reject(err);
    }
  };
}
