import type * as Stitches from "@stitches/react";
import React from "react";
import { styled } from "stitches.config";

export const StyledChip = styled("div", {
  display: "inline-flex",
  alignItems: "center",
  justifyContent: "center",
  borderRadius: "64px",
  fontSize: "$s",
  lineHeight: "$s",
  fontWeight: "$semiBold",
  whiteSpace: "nowrap",

  variants: {
    size: {
      default: {
        px: "$chipPaddingX",
        py: "$chipPaddingY",
      },
      small: {
        px: "$chipSmallPaddingX",
        py: "$chipSmallPaddingY",
      },
    },
    color: {
      default: {
        backgroundColor: "$grey75",
        color: "$grey250",
      },
      secondary: {
        backgroundColor: "$btnSecondaryBg",
        color: "$btnSecondaryColor",
      },
      primary: {
        // backgroundColor: '$btnBg',
        // color: '$btnColor',
      },
      error: {
        backgroundColor: "$red50",
        color: "$red200",
      },
      info: {
        backgroundColor: "$grey75",
        color: "$black",
      },
      success: {
        backgroundColor: "$emerald100",
        color: "$emerald200",
      },
      warning: {
        backgroundColor: "$orange100",
        color: "#F6A300",
      },
    },
  },

  defaultVariants: {
    color: "default",
    size: "default",
  },
});

export type ChipColorVariants = Stitches.VariantProps<
  typeof StyledChip
>["color"];

type StyledChipVariants = Stitches.VariantProps<typeof StyledChip>;

export type ChipProps = Pick<StyledChipVariants, "size" | "color"> & {
  children?: React.ReactNode;
  className?: string;
};

export const Chip: React.FC<ChipProps> = React.memo(
  ({ className, children, ...props }) => {
    return (
      <StyledChip {...props} className={className} {...props}>
        {children}
      </StyledChip>
    );
  }
);

Chip.displayName = "Chip";
