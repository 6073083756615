import React from "react";
import { styled } from "stitches.config";

const HamburgerIconOpen = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="22"
    height="16"
    viewBox="0 0 22 16"
    fill="none"
  >
    <path
      d="M15.6749 10.62C15.4652 10.7997 15.441 11.1153 15.6207 11.325C15.8004 11.5347 16.116 11.5589 16.3257 11.3792L15.6749 10.62ZM16.3257 4.61998C16.116 4.44027 15.8004 4.46455 15.6207 4.67421C15.441 4.88388 15.4652 5.19953 15.6749 5.37924L16.3257 4.61998ZM19.5003 7.99961L19.8257 8.37924C19.9365 8.28425 20.0003 8.14557 20.0003 7.99961C20.0003 7.85365 19.9365 7.71497 19.8257 7.61998L19.5003 7.99961ZM1.3999 1.59961V0.599609C0.847618 0.599609 0.399902 1.04732 0.399902 1.59961H1.3999ZM1.3999 1.59961H0.399902C0.399902 2.15189 0.847618 2.59961 1.3999 2.59961V1.59961ZM20.5999 1.59961H1.3999V2.59961H20.5999V1.59961ZM20.5999 1.59961V2.59961C21.1522 2.59961 21.5999 2.15189 21.5999 1.59961H20.5999ZM20.5999 1.59961H21.5999C21.5999 1.04732 21.1522 0.599609 20.5999 0.599609V1.59961ZM1.3999 1.59961H20.5999V0.599609H1.3999V1.59961ZM1.3999 6.99961C0.847618 6.99961 0.399902 7.44732 0.399902 7.99961H1.3999V6.99961ZM13.5999 6.99961H1.3999V7.99961H13.5999V6.99961ZM14.5999 7.99961C14.5999 7.44732 14.1522 6.99961 13.5999 6.99961V7.99961H14.5999ZM13.5999 8.99961C14.1522 8.99961 14.5999 8.55189 14.5999 7.99961H13.5999V8.99961ZM1.3999 8.99961H13.5999V7.99961H1.3999V8.99961ZM0.399902 7.99961C0.399902 8.55189 0.847618 8.99961 1.3999 8.99961V7.99961H0.399902ZM1.3999 13.3996C0.847617 13.3996 0.399902 13.8473 0.399902 14.3996H1.3999V13.3996ZM20.5999 13.3996H1.3999V14.3996H20.5999V13.3996ZM21.5999 14.3996C21.5999 13.8473 21.1522 13.3996 20.5999 13.3996V14.3996H21.5999ZM20.5999 15.3996C21.1522 15.3996 21.5999 14.9519 21.5999 14.3996H20.5999V15.3996ZM1.3999 15.3996H20.5999V14.3996H1.3999V15.3996ZM0.399902 14.3996C0.399902 14.9519 0.847617 15.3996 1.3999 15.3996V14.3996H0.399902ZM16.3257 11.3792L19.8257 8.37924L19.1749 7.61998L15.6749 10.62L16.3257 11.3792ZM19.8257 7.61998L16.3257 4.61998L15.6749 5.37924L19.1749 8.37924L19.8257 7.61998Z"
      fill="#A1AAA8"
    />
  </svg>
);

const HamburgerIconClosed = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="22"
    height="16"
    viewBox="0 0 22 16"
    fill="none"
  >
    <path
      d="M19.1749 11.3792C19.3846 11.5589 19.7002 11.5347 19.8799 11.325C20.0596 11.1153 20.0354 10.7997 19.8257 10.62L19.1749 11.3792ZM19.8257 5.37924C20.0354 5.19953 20.0596 4.88388 19.8799 4.67421C19.7002 4.46455 19.3846 4.44027 19.1749 4.61998L19.8257 5.37924ZM16.0003 7.99961L15.6749 7.61998C15.5641 7.71497 15.5003 7.85365 15.5003 7.99961C15.5003 8.14557 15.5641 8.28425 15.6749 8.37924L16.0003 7.99961ZM1.3999 1.59961V0.599609C0.847618 0.599609 0.399902 1.04732 0.399902 1.59961H1.3999ZM1.3999 1.59961H0.399902C0.399902 2.15189 0.847618 2.59961 1.3999 2.59961V1.59961ZM20.5999 1.59961H1.3999V2.59961H20.5999V1.59961ZM20.5999 1.59961V2.59961C21.1522 2.59961 21.5999 2.15189 21.5999 1.59961H20.5999ZM20.5999 1.59961H21.5999C21.5999 1.04732 21.1522 0.599609 20.5999 0.599609V1.59961ZM1.3999 1.59961H20.5999V0.599609H1.3999V1.59961ZM1.3999 6.99961C0.847618 6.99961 0.399902 7.44732 0.399902 7.99961H1.3999V6.99961ZM13.5999 6.99961H1.3999V7.99961H13.5999V6.99961ZM14.5999 7.99961C14.5999 7.44732 14.1522 6.99961 13.5999 6.99961V7.99961H14.5999ZM13.5999 8.99961C14.1522 8.99961 14.5999 8.55189 14.5999 7.99961H13.5999V8.99961ZM1.3999 8.99961H13.5999V7.99961H1.3999V8.99961ZM0.399902 7.99961C0.399902 8.55189 0.847618 8.99961 1.3999 8.99961V7.99961H0.399902ZM1.3999 13.3996C0.847617 13.3996 0.399902 13.8473 0.399902 14.3996H1.3999V13.3996ZM20.5999 13.3996H1.3999V14.3996H20.5999V13.3996ZM21.5999 14.3996C21.5999 13.8473 21.1522 13.3996 20.5999 13.3996V14.3996H21.5999ZM20.5999 15.3996C21.1522 15.3996 21.5999 14.9519 21.5999 14.3996H20.5999V15.3996ZM1.3999 15.3996H20.5999V14.3996H1.3999V15.3996ZM0.399902 14.3996C0.399902 14.9519 0.847617 15.3996 1.3999 15.3996V14.3996H0.399902ZM19.8257 10.62L16.3257 7.61998L15.6749 8.37924L19.1749 11.3792L19.8257 10.62ZM16.3257 8.37924L19.8257 5.37924L19.1749 4.61998L15.6749 7.61998L16.3257 8.37924Z"
      fill="#A1AAA8"
    />
  </svg>
);

export type HamburgerButtonProps = {
  onClick(): void;
  isShown?: boolean;
  className?: string;
};

const StyledButton = styled("button", {
  px: "$sidebarLinkPaddingX",
  py: "$sidebarLinkPaddingY",
  color: "$white",

  "& svg": {
    height: "21px",
    width: "21px",
  },
});

export const HamburgerButton: React.FC<HamburgerButtonProps> = React.memo(
  ({ isShown, onClick, className }) => {
    return (
      <StyledButton onClick={onClick} className={className}>
        {isShown ? <HamburgerIconClosed /> : <HamburgerIconOpen />}
      </StyledButton>
    );
  }
);

HamburgerButton.displayName = "HamburgerButton";
