import { IColor } from "@/helpers/interfaces";

const baseColors: IColor = {
  white: "#ffffff",
  black: "#242726",
  green400: "#29554A",
  green400_1: "#29554a19",
  green400_3: "#29554a4c",
  green300: "#79A398",
  green200: "#1D9C7C",
  green100: "#80CEBA",
  green75: "#ACCCC6",
  green50: "#D6E0DE",
  green25: "#D6E0DE",
  blue200: "#309CFF",
  blue100: "#83C3FF",
  pink200: "#FF5592",
  ping100: "#FF99BE",
  grey400: "#404342",
  grey300: "#5C6763",
  grey250: "#9B9B9B",
  grey200: "#A1AAA8",
  grey100: "#C3C3C8",
  grey100_1: "#c3c3c819",
  grey100_4: "#c3c3c866",
  grey75: "#E1E1E6",
  grey50: "#F3F3F3",
  grey25: "#F6F6F4",
  red200: "#FF531A",
  red100: "#FF9F8A",
  red50: "#FFEAE3",
  emerald200: "#30A33B",
  emerald100: "#E3F3E5",
  emerald50: "#F6FAF6",
  orange200: "#F6A300",
  orange100: "#FCEDD0",
  orange50: "#FBF6EA",
};

const extendedColors: IColor = {
  brand: baseColors.blue,
  error: baseColors.pink,
  warning: baseColors.orange,
  buttonPrimary: baseColors.blue,
  buttonSecondary: baseColors.black,
};

export const colors = { ...baseColors, ...extendedColors };
