export const requiredValidationRule = {
  required: "Это поле обязательное",
};

export const emailValidationRule = {
  required: "Это поле обязательное",
  validate: (value: string) => {
    const myEmailRegex = /^[\w-.]+@([\w-]+\.)+[\w-]{2,15}$/;
    return myEmailRegex.test(value) || "Введен неверный email";
  },
};

export const emailValidationWithoutRequired = {
  validate: (value: string) => {
    const myEmailRegex = /^[\w-.]+@([\w-]+\.)+[\w-]{2,15}$/;
    if (!value) return true;
    return myEmailRegex.test(value) || "Неверный формат почты";
  },
};

export const codeValidationRule = (length: number) => ({
  required: "Это поле обязательное",
  validate: (value: string) => {
    return (
      value.replace(/[^\d]/g, "").length === length || "Код введен некорректно"
    );
  },
});

export const phoneValidationRule = {
  required: "Это поле обязательное",
  validate: (value: string) => {
    const phoneRegExp = /^((8|\+7)[- ]?)?(\(?\d{3}\)?[- ]?)?[\d\- ]{7,10}$/;
    return phoneRegExp.test(value) || "Неверный формат телефона";
  },
};

export const passwordValidationRule = {
  required: "Это поле обязательное",
  validate: (value: string) => {
    const myPasswordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}$/g;
    return (
      myPasswordRegex.test(value) ||
      "8 латинских символов, 1 прописная, заглавная буквы, 1 цифра"
    );
  },
};

export const enterPasswordValidationRule = {
  required: "Это поле обязательное",
};
