import { colors } from "@/helpers/styleColors";
import { Text, Title } from "@/uikit";
import styled from "styled-components";

export const StyledIdentityContent = styled.div`
  width: 100%;
  height: 100vh;
  background: ${colors.green400};
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const StyledIdentityContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 40px;
  max-width: 440px;
  width: 100%;
  background: ${colors.white};
  border-radius: 8px;
`;

export const StyledIdentityContentLogo = styled.div`
  cursor: default;
`;

export const StyledIdentityContentTitle = styled(Title)`
  font-style: normal;
  font-weight: 400;
  font-size: 28px;
  line-height: 30px;
  text-align: center;
  color: ${colors.black};
  margin-top: 24px;
`;

export const StyledIdentityContentSubTitle = styled(Text)`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  margin-top: 32px;
`;

export const StyledIdentityContentBlock = styled.div`
  width: 100%;
  margin-top: 32px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
`;
