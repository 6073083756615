export const TemperatureIcon = () => (
  <svg
    width="7"
    height="13"
    viewBox="0 0 7 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3.93359 11.0667C4.66997 11.0667 5.26693 10.4698 5.26693 9.7334C5.26693 8.99702 4.66997 8.40007 3.93359 8.40007C3.19721 8.40007 2.60026 8.99702 2.60026 9.7334C2.60026 10.4698 3.19721 11.0667 3.93359 11.0667Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.93359 0.733398C3.03595 0.733398 2.30826 1.46109 2.30826 2.35873V7.21146C1.48172 7.7452 0.933594 8.67509 0.933594 9.7334C0.933594 11.3903 2.27674 12.7334 3.93359 12.7334C5.59045 12.7334 6.93359 11.3903 6.93359 9.7334C6.93359 8.67509 6.38547 7.7452 5.55893 7.21146V2.35873C5.55893 1.46109 4.83124 0.733398 3.93359 0.733398ZM2.97492 2.35873C2.97492 1.82928 3.40414 1.40007 3.93359 1.40007C4.46305 1.40007 4.89226 1.82928 4.89226 2.35873V7.40007C4.89226 7.52118 4.95796 7.63277 5.06386 7.69153C5.78205 8.09006 6.26693 8.85533 6.26693 9.7334C6.26693 11.0221 5.22226 12.0667 3.93359 12.0667C2.64493 12.0667 1.60026 11.0221 1.60026 9.7334C1.60026 8.85533 2.08513 8.09006 2.80333 7.69153C2.90923 7.63277 2.97492 7.52118 2.97492 7.40007V2.35873Z"
      fill="currentColor"
    />
  </svg>
);
