import { ChevronDownIcon, ChevronUpIcon } from "@radix-ui/react-icons";
import React, { useMemo, useRef, useState } from "react";
import { styled } from "stitches.config";
import { StyledFieldBox, StyledFieldIcon } from "../styles";
import {
  DropdownMenuTrigger,
  DropdownMenuContent,
  DropdownMenu,
  DropdownMenuCheckboxItem,
} from "../dropdown";
import { setNativeSelectValue } from "../../utils";

type CommonProps = {
  children?: React.ReactNode;
  name: string;
  label: string;
  defaultValue?: string;
  size?: "default" | "small" | "large";
  isDisabled?: boolean;
  className?: string;
  onBlur?: () => void;
};

export type SingleSelectProps = CommonProps & {
  multiple?: false;
  value?: string;
  onChange?: (value: string) => void;
};

export type MultipleSelectProps = CommonProps & {
  multiple: true;
  value?: string[];
  onChange?: (value: string[]) => void;
};

const StyledSelect = styled("select", {
  position: "absolute",
  border: "0",
  width: "1px",
  height: "1px",
  padding: "0",
  margin: "-1px",
  overflow: "hidden",
  clip: "rect(0, 0, 0, 0)",
  whiteSpace: "nowrap",
  wordWrap: "normal",
});

export const StyledTrigger = styled("button", {
  width: "100%",
  height: "100%",
  textAlign: "left",

  "&:focus-visible": {
    outline: "none",
  },
});

export const Select: React.FC<SingleSelectProps | MultipleSelectProps> = ({
  label,
  className,
  children,
  size,
  isDisabled,
  name,
  defaultValue: _defaultValue,
  ...props
}) => {
  const defaultValue = _defaultValue ?? "";
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const selectEl = useRef<HTMLSelectElement>(null);
  const childrenArray = React.Children.toArray(children);

  const values = useMemo(
    () =>
      childrenArray.reduce<{
        list: { value: string; label: string }[];
        map: { [value: string]: string };
      }>(
        (result, child) => {
          if (child && React.isValidElement<SelectItemProps>(child)) {
            return {
              ...result,
              list: [
                ...result.list,
                {
                  value: child.props.value,
                  label: child.props.children,
                },
              ],
              map: {
                ...result.map,
                [child?.props.value]: child.props.children,
              },
            };
          }

          return result;
        },
        {
          list: [],
          map: {},
        }
      ),
    []
  );
  const { list, map } = values;

  const wrappedChildren = useMemo(
    () =>
      list.map((option) => {
        if (
          ("multiple" in props && props.multiple === false) ||
          !("multiple" in props)
        ) {
          const value = props.value;
          const onChange = props.onChange;
          const checked =
            value === option.value || selectEl?.current?.value === option.value;

          return (
            <DropdownMenuCheckboxItem
              key={`${name}-select-${option.value}`}
              checked={checked}
              onCheckedChange={() => {
                const newValue = checked ? "" : option.value;

                if (onChange) {
                  onChange(newValue);
                }
                if (selectEl?.current) {
                  setNativeSelectValue(selectEl.current, newValue);
                }
              }}
            >
              {option.label}
            </DropdownMenuCheckboxItem>
          );
        }
        // if ('multiple' in props && props.multiple === true) {
        //   return (
        //     <DropdownMenuCheckboxItem
        //       // checked={props.value.includes(child.props.value)}
        //       onSelect={(e) => e.preventDefault()}
        //       onCheckedChange={(isChecked) => {
        //         // const value = props.value;
        //         // const onChange = props.onChange;
        //         // const newValue = isChecked
        //         //   ? [...value, child.props.value]
        //         //   : value.filter((val) => val !== child.props.value);
        //         // if (onChange) {
        //         //   onChange(option.value);
        //         // }
        //         // if (selectEl?.current) {
        //         //   setNativeSelectValue(selectEl.current, option.value);
        //         // }
        //       }}
        //     >
        //       {option.label}
        //     </DropdownMenuCheckboxItem>
        //   );
        // }
      }),
    [list, name, props]
  );

  const renderValue = map[selectEl?.current?.value ?? defaultValue];

  const options = useMemo(
    () =>
      list.map((option) => {
        return (
          <option key={`${name}-native-${option.value}`} value={option.value}>
            {option.label}
          </option>
        );
      }),
    []
  );

  return (
    <DropdownMenu onOpenChange={setIsOpen}>
      <StyledSelect
        defaultValue={defaultValue}
        name={name}
        ref={selectEl}
        id={name}
        multiple={props.multiple}
      >
        <option disabled value="" />
        {options}
      </StyledSelect>
      <StyledFieldBox
        isCircle
        isIcon
        isFocus={isOpen}
        size={size}
        isDisabled={isDisabled}
        aria-labelledby={label}
        className={className}
      >
        <DropdownMenuTrigger asChild disabled={isDisabled}>
          <StyledTrigger data-field-inner>
            {renderValue || label}
            <StyledFieldIcon>
              {isOpen ? <ChevronUpIcon /> : <ChevronDownIcon />}
            </StyledFieldIcon>
          </StyledTrigger>
        </DropdownMenuTrigger>
      </StyledFieldBox>
      <DropdownMenuContent sideOffset={5}>
        {wrappedChildren}
      </DropdownMenuContent>
    </DropdownMenu>
  );
};

Select.displayName = "Select";

export type SelectItemProps = {
  value: string;
  label?: string;
  children: string;
};

export const SelectItem: React.FC<SelectItemProps> = ({ children }) => {
  return <div>{children}</div>;
};
