import { Button, ButtonLink, Input, Title } from "@/uikit";
import { styled } from "stitches.config";

export const StyledProfileWrapper = styled("div", {
  background: "$grey25",
  width: "100%",
  height: "100vh",
});

export const StyledProfileContainer = styled("div", {
  maxWidth: "1440px",
  padding: "22px 36px",
  margin: "0 auto",
  width: "100%",
});

export const StyledProfileBackLink = styled(ButtonLink, {
  fontStyle: "normal",
  fontWeight: "400",
  fontSize: "14px",
  lineHeight: "20px",
  color: "$black",
  display: "flex",
  alignItems: "center",
  "& svg": {
    marginRight: "8px",
  },
});

export const StyledProfileTitle = styled(Title, {
  fontStyle: "normal",
  fontWeight: "700",
  fontSize: "32px",
  lineHeight: "36px",
  color: "$black",
  marginTop: "22px",
});

export const StyledProfileBlock = styled("form", {
  padding: "24px 16px 16px",
  width: "45%",
  background: "$white",
  borderRadius: "8px",
  marginTop: "24px",
  display: "grid",
  gridTemplateColumns: "1fr",
  gap: "24px",
});

export const StyledProfileInput = styled(Input, {
  width: "55%",
});

export const StyledProfileButton = styled(Button, {
  width: "30%",
});
