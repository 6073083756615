import React, { useEffect, useState } from "react";
import IdentityContent from "@/components/identityContent";
import {
  RegistrationInputItem,
  RegistrationInputList,
  RegistrationLinkTab,
  RegistrationLinkTabs,
  RegistrationNextButton,
  RegistrationForm as StyledRegistrationForm,
} from "@/pages/registration/index.style";
import { Button, ButtonLink, Input, InputProps } from "@/uikit";
import { Controller } from "@/components/Controller";
import { emailValidationRule, passwordValidationRule } from "@/helpers/consts";
import { UpdateRegistrationFlowWithPasswordMethod } from "@ory/kratos-client";
import { INodeError, IUserCreate } from "@/helpers/interfaces";
import { useForm } from "react-hook-form";
import { getError } from "@/ory/helpers";

interface IRegistrationForm {
  token: string;
  error?: string | INodeError;
  isPending?: boolean;
  setError: (value: string) => void;
  onSubmit: (data: UpdateRegistrationFlowWithPasswordMethod) => void;
}

const RegistrationForm: React.VFC<IRegistrationForm> = (props) => {
  const { token, error, isPending, setError, onSubmit } = props;
  const [profile, setProfile] = useState<IUserCreate>({
    email: "",
  });
  const [data, setData] = useState<
    UpdateRegistrationFlowWithPasswordMethod & {
      traits: IUserCreate;
    }
  >({
    csrf_token: "",
    method: "password",
    password: "",
    traits: profile,
  });
  const [passwordConfirm, setPasswordConfirm] = useState("");

  useEffect(() => {
    if (token)
      setData((prevState) => ({
        ...prevState,
        csrf_token: token,
      }));
  }, [token]);

  const {
    formState: { errors },
    handleSubmit,
    control,
  } = useForm({
    mode: "onBlur",
    defaultValues: {
      ...profile,
      ...data,
      passwordConfirm,
    },
  });
  const handleProfileChange = (name: keyof IUserCreate, value: string) => {
    setError("");
    setProfile((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleDataChange = (
    name: keyof UpdateRegistrationFlowWithPasswordMethod,
    value: string
  ) => {
    setError("");
    setData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleValidationSubmit = () => {
    if (data.password === passwordConfirm)
      onSubmit({ ...data, traits: profile });
  };

  return (
    <IdentityContent title="Создать учетную запись">
      <RegistrationLinkTabs>
        <RegistrationLinkTab>Уже есть учетная запись?</RegistrationLinkTab>
        <ButtonLink href="/login">Войти</ButtonLink>
      </RegistrationLinkTabs>
      <StyledRegistrationForm onSubmit={handleSubmit(handleValidationSubmit)}>
        <RegistrationInputList>
          <RegistrationInputItem>
            <Controller<InputProps>
              value={profile.email}
              name={"email"}
              rules={emailValidationRule}
              placeholder={"Введите email*"}
              type="text"
              autoComplete="email"
              error={getError(error, "email") || errors?.email?.message}
              control={control}
              isBlock={true}
              component={(inputProps: InputProps) => <Input {...inputProps} />}
              onChange={(value: string) =>
                handleProfileChange("email", value.toLowerCase())
              }
            />
          </RegistrationInputItem>
          <RegistrationInputItem>
            <Controller<InputProps>
              value={data.password}
              name={"password"}
              placeholder={"Пароль*"}
              type={"password"}
              rules={passwordValidationRule}
              autoComplete="new-password"
              error={getError(error, "password") || errors?.password?.message}
              control={control}
              isBlock={true}
              component={(inputProps: InputProps) => <Input {...inputProps} />}
              onChange={(value: string) => handleDataChange("password", value)}
            />
          </RegistrationInputItem>
          <RegistrationInputItem>
            <Controller<InputProps>
              name={"passwordConfirm"}
              error={errors?.passwordConfirm?.message}
              control={control}
              component={(inputProps: InputProps) => <Input {...inputProps} />}
              rules={{
                required: "Это поле обязательное",
                validate: (value: string) => {
                  return value === data.password || "Пароль не совпадает";
                },
              }}
              isError={!!getError(error, "password")}
              value={passwordConfirm}
              placeholder={"Подтвердите пароль*"}
              type={"password"}
              isBlock={true}
              autoComplete="off"
              onChange={setPasswordConfirm}
            />
          </RegistrationInputItem>
        </RegistrationInputList>
        <RegistrationNextButton>
          <Button
            kind="primary"
            type="submit"
            size="small"
            isBlock={true}
            isLoading={isPending}
          >
            Продолжить
          </Button>
        </RegistrationNextButton>
      </StyledRegistrationForm>
    </IdentityContent>
  );
};

export default RegistrationForm;
