import React from "react";
import { styled } from "stitches.config";
import { keyframes } from "@stitches/react";

export interface ILoader {
  className?: string;
  size?: 24 | 20 | 15;
  theme?: "white";
  isScreen?: boolean;
}

const rotation = keyframes({
  "0%": { transform: "rotate(0deg)" },
  "100%": { transform: "rotate(360deg)" },
});

const StyledLoaderWrapper = styled("div", {
  variants: {
    isScreen: {
      true: {
        width: "100%",
        flex: "1",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      },
    },
  },
});

const StyledLoader = styled("div", {
  borderRadius: "50%",
  border: "3px solid",
  animation: `${rotation} 1s linear infinite`,

  variants: {
    size: {
      15: {
        sz: "15px",
        borderWidth: "2px",
        marginBottom: "1px"
      },
      20: {
        sz: "20px",
      },
      24: {
        sz: "24px",
      },
    },
    theme: {
      white: {
        borderColor: "$white",
        borderTopColor: "transparent",
      },
    },
  },
});

const Loader: React.VFC<ILoader> = (props) => {
  const { className, size = 24, theme = "white", isScreen = false } = props;

  return (
    <StyledLoaderWrapper className={className} isScreen={isScreen}>
      <StyledLoader size={size} theme={theme} />
    </StyledLoaderWrapper>
  );
};

export { Loader };
