export const PowerIcon = () => (
  <svg
    width="8"
    height="12"
    viewBox="0 0 8 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.41924 0.613899C2.46355 0.476048 2.59179 0.382568 2.73659 0.382568H6.59373C6.71031 0.382568 6.81841 0.44347 6.87882 0.543174C6.93923 0.642878 6.94316 0.766896 6.88919 0.870226L5.00104 4.48513H7.0223C7.14983 4.48513 7.26617 4.55789 7.322 4.67255C7.37782 4.7872 7.36334 4.92366 7.28469 5.02404L2.14183 11.5881C2.04512 11.7116 1.87545 11.7509 1.73428 11.6826C1.59312 11.6143 1.51864 11.4569 1.55539 11.3044L2.74192 6.38257H1.0223C0.915785 6.38257 0.815683 6.33166 0.752938 6.24559C0.690194 6.15951 0.672363 6.04864 0.704958 5.94723L2.41924 0.613899ZM2.97957 1.04923L1.47957 5.7159H3.16516C3.26731 5.7159 3.36382 5.76274 3.42703 5.84299C3.49023 5.92324 3.51315 6.02805 3.48921 6.12735L2.56292 9.96973L6.33768 5.1518H4.45087C4.3343 5.1518 4.22619 5.0909 4.16578 4.99119C4.10537 4.89149 4.10144 4.76747 4.15541 4.66414L6.04356 1.04923H2.97957Z"
      fill="currentColor"
    />
  </svg>
);
