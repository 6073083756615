export const HashrateIcon = () => (
  <svg
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.66667 3.33333H4V8.66667H4.66667V6.33333H7.33333V8.66667H8V3.33333H7.33333V5.66667H4.66667V3.33333Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 6C12 9.31371 9.31371 12 6 12C2.68629 12 0 9.31371 0 6C0 2.68629 2.68629 0 6 0C9.31371 0 12 2.68629 12 6ZM11.3333 6C11.3333 8.94552 8.94552 11.3333 6 11.3333C3.05448 11.3333 0.666667 8.94552 0.666667 6C0.666667 3.05448 3.05448 0.666667 6 0.666667C8.94552 0.666667 11.3333 3.05448 11.3333 6Z"
      fill="currentColor"
    />
  </svg>
);
