import {
  FrontendApi,
  Configuration as KratosConfig,
} from "@ory/kratos-client";
import { OAuth2Api, Configuration as HydraConfig } from "@ory/hydra-client";
import { INodeError } from "@/helpers/interfaces";
import { callWithRetry } from "@/helpers/utils";
import axios from "@ory/hydra-client/node_modules/axios";

const axiosInstance = axios.create();

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response?.data?.error?.id === "security_csrf_violation") {
      return callWithRetry(() => axios(error.response.config), 0);
    }
    return Promise.reject(error);
  }
);

const baseURL = process.env.REACT_APP_ORY_KRATOS_URL || `${location.origin}`;
const kratos = new FrontendApi(
  new KratosConfig({
    basePath: baseURL,
    baseOptions: {
      withCredentials: true,
    },
  }),
  "",
  axiosInstance
);


const hydra = new OAuth2Api(
  new HydraConfig({
    basePath: baseURL,
    baseOptions: {
      withCredentials: true,
    },
  })
);

const getError = (error?: string | INodeError, node?: string) => {
  return error
    ? typeof error === "string"
      ? error
      : error.node === node
      ? error.message
      : ""
    : "";
};

export { hydra, kratos, getError };
