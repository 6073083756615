export const ChipIcon = () => (
  <svg
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.00016 4.33334C4.63197 4.33334 4.3335 4.63182 4.3335 5.00001V9.00001C4.3335 9.3682 4.63197 9.66668 5.00016 9.66668H9.00016C9.36835 9.66668 9.66683 9.3682 9.66683 9.00001V5.00001C9.66683 4.63182 9.36835 4.33334 9.00016 4.33334H5.00016Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.00016 0.333344H4.3335V1.66668H3.00016C2.26378 1.66668 1.66683 2.26363 1.66683 3.00001V4.33334H0.333496V5.00001H1.66683V9.00001H0.333496V9.66668H1.66683V11C1.66683 11.7364 2.26378 12.3333 3.00016 12.3333H4.3335V13.6667H5.00016V12.3333H9.00016V13.6667H9.66683V12.3333H11.0002C11.7365 12.3333 12.3335 11.7364 12.3335 11V9.66668H13.6668V9.00001H12.3335V5.00001H13.6668V4.33334H12.3335V3.00001C12.3335 2.26363 11.7365 1.66668 11.0002 1.66668H9.66683V0.333344H9.00016V1.66668H5.00016V0.333344ZM2.3335 3.00001C2.3335 2.63182 2.63197 2.33334 3.00016 2.33334H11.0002C11.3684 2.33334 11.6668 2.63182 11.6668 3.00001V11C11.6668 11.3682 11.3684 11.6667 11.0002 11.6667H3.00016C2.63197 11.6667 2.3335 11.3682 2.3335 11V3.00001Z"
      fill="currentColor"
    />
  </svg>
);
