import { FC } from "react";
import { ReactComponent as LogoIcon } from "@/assets/icons/logo.svg";
import { IdentityContentProps } from "./interfaces";
import {
  StyledIdentityContent,
  StyledIdentityContentWrapper,
  StyledIdentityContentLogo,
  StyledIdentityContentTitle,
  StyledIdentityContentBlock,
  StyledIdentityContentSubTitle,
} from "./index.style";

const IdentityContent: FC<IdentityContentProps> = (props) => {
  const { children, title, subTitle, isError } = props;

  // TODO: temporary solution, as there is a header flip in the layout
  const prepareTitle = (title: string) => {
    return title.split("<br/>").map((el: string, _, arr) => {
      return arr.length > 1 ? (
        <>
          {el}
          <br />
        </>
      ) : (
        el
      );
    });
  };

  return (
    <StyledIdentityContent>
      <StyledIdentityContentWrapper>
        <StyledIdentityContentLogo>
          <LogoIcon />
        </StyledIdentityContentLogo>
        <StyledIdentityContentTitle>
          {prepareTitle(title)}
        </StyledIdentityContentTitle>
        {subTitle ? (
          <StyledIdentityContentSubTitle kind={isError ? "error" : "default"}>
            {subTitle}
          </StyledIdentityContentSubTitle>
        ) : (
          <></>
        )}
        <StyledIdentityContentBlock>{children}</StyledIdentityContentBlock>
      </StyledIdentityContentWrapper>
    </StyledIdentityContent>
  );
};

export default IdentityContent;
