export const QuestionMarkIcon = () => (
  <svg
    width="6"
    height="8"
    viewBox="0 0 6 8"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3 0.960547C2.2008 0.960547 1.41563 1.59339 1.41563 2.39491C1.41563 2.514 1.31909 2.61054 1.2 2.61054C1.08091 2.61054 0.984375 2.514 0.984375 2.39491C0.984375 1.29959 2.02235 0.529297 3 0.529297C3.97765 0.529297 5.01562 1.29959 5.01562 2.39491C5.01562 3.31924 4.49905 3.77731 4.05048 4.17508L4.04307 4.18166C3.59228 4.58143 3.21562 4.92126 3.21562 5.63491C3.21562 5.754 3.11909 5.85053 3 5.85053C2.88091 5.85053 2.78437 5.754 2.78437 5.63491C2.78437 4.72129 3.29732 4.26651 3.74345 3.87096L3.75693 3.85901C4.20667 3.46018 4.58437 3.11644 4.58437 2.39491C4.58437 1.59339 3.7992 0.960547 3 0.960547Z"
      fill="currentColor"
    />
    <path
      d="M3 7.47215C3.18122 7.47215 3.32812 7.32525 3.32812 7.14403C3.32812 6.96281 3.18122 6.8159 3 6.8159C2.81878 6.8159 2.67187 6.96281 2.67187 7.14403C2.67187 7.32525 2.81878 7.47215 3 7.47215Z"
      fill="currentColor"
    />
  </svg>
);
