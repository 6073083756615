import React, { useState } from "react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { globalStyles } from "stitches.config";
import {
  DEFAULT_GLOBAL_STATE,
  IGlobalState,
  ContextGlobalState,
} from "@/contexts/GlobalContext";
import RouterProvider from "@/pages/_routerProfider";
import { BrowserRouter } from "react-router-dom";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchInterval: false,
      refetchOnWindowFocus: false,
      refetchIntervalInBackground: false,
      refetchOnMount: false,
      retry: 1,
      retryOnMount: false,
    },
  },
});

const App: React.FC = (): JSX.Element => {
  const globalState = useState<IGlobalState>(DEFAULT_GLOBAL_STATE);
  globalStyles();

  return (
    <QueryClientProvider client={queryClient}>
      <ContextGlobalState.Provider value={globalState}>
        <BrowserRouter>
          <RouterProvider />
        </BrowserRouter>
      </ContextGlobalState.Provider>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  );
};

export default App;
