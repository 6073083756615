import { CopyIcon } from "@radix-ui/react-icons";
import { useTranslation } from "react-i18next";
import { styled } from "stitches.config";
import { Tooltip } from "../../tooltip";
import { Text } from "../../typography";

const CopyToClipboardWrapper = styled("button", {
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  width: "100%",
  border: "1px solid $inputBorderColor",
  borderRadius: "$fieldBr",
  padding: "18px 16px",
  margin: "0",
  cursor: "pointer",
  outline: "none",
  color: "$black",
  "& svg": {
    color: "$grey300",
    width: "21px",
    height: "100%",
  },
});

export const CopyToClipboard: React.FC<{ value: string }> = ({ value }) => {
  const handleCopy = () => {
    navigator.clipboard.writeText(value);
  };
  const { t } = useTranslation("common");

  return (
    <Tooltip
      content={t("copiedToClipboard")}
      options={{ side: "right", sideOffset: 35 }}
      hideTimeout={1500}
    >
      <CopyToClipboardWrapper type="button" onClick={handleCopy}>
        <Text>{value}</Text>
        <CopyIcon />
      </CopyToClipboardWrapper>
    </Tooltip>
  );
};
