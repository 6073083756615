import { styled } from "stitches.config";
import { Button, ButtonLink, Text } from "@/uikit";

export const StyledLoginConfirmWrapper = styled("div", {
  width: "100%",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
});

export const StyledLoginConfirmText = styled(Text, {
  fontStyle: "normal",
  fontWeight: "400",
  fontSize: "14PX",
  lineHeight: "20px",
  textAlign: "center",
  color: "$black",
  marginTop: "32px",
  width: "95%",

  "&:first-child": {
    marginTop: "0",
  },
});

export const StyledLoginConfirmButton = styled(Button, {
  width: "100%",
  marginTop: "56px",
});

export const StyledButtonLink = styled(ButtonLink, {
  display: "inline-block",
});
