import { createStitches } from "@stitches/react";

export const { styled, createTheme, globalCss, getCssText, theme, keyframes } =
  createStitches({
    theme: {
      sizes: {
        headerHeight: "64px",
        sidebarWidth: "240px",
        mobileSidebarWidth: "70%",
        sidebarLinkIconSize: "21px",
        btnIconSize: "20px",
        btnSmallIconSize: "20px",
        fieldIconSize: "18px",
        fieldSmallIconSize: "15px",
        fieldLargeIconSize: "22px",
        tooltipMaxWidth: "350px",
      },
      space: {
        headerMargin: "8px",
        sidebarPaddingX: "16px",
        sidebarPaddingY: "0",
        sidebarLinkPaddingX: "13px",
        sidebarLinkPaddingY: "12px",
        sidebarLinkGap: "4px",
        sidebarLinkIconIndent: "13px",
        contentPaddingX: "40px",
        contentBlockPaddingX: "24px",
        contentBlockPaddingY: "24px",
        contentBlockMobilePaddingX: "16px",
        contentBlockMobilePaddingY: "8px",
        contentBlockMobilePadding: "8px",
        contentBlockHeadIndent: "20px",
        contentBlockHeadIndentMobile: "16px",
        contentBlockGap: "24px",
        contentBlockMediumGap: "12px",
        contentBlockMobileGap: "12px",
        contentBlockSmallGap: "8px",
        contentBlockSmallMobileGap: "4px",
        contentHeaderMargin: "24px",
        btnPaddingX: "20px",
        btnPaddingY: "14px",
        btnGap: "8px",
        btnSmallPaddingX: "20px",
        btnSmallPaddingY: "8px",
        btnSmallGap: "10px",
        btnWithOnlyIconPadding: "16px",
        btnSmallWithOnlyIconPadding: "8px",
        inputPaddingX: "16px",
        inputPaddingY: "18px",
        inputSmallPaddingTop: "8px",
        inputSmallPaddingBottom: "8px",
        inputSmallPaddingX: "16px",
        inputLargePaddingTop: "27px",
        inputLargePaddingBottom: "9px",
        inputLargePaddingX: "16px",
        chipPaddingX: "12px",
        chipPaddingY: "8px",
        chipSmallPaddingX: "8px",
        chipSmallPaddingY: "0",
        tooltipPadding: "8px 16px",
        dropdownItemPadding: "8px",
        togglePadding: "10px 24px",
        tableGap: "8px",
        tableGapSmall: "4px",
        tablePadding: "16px",
        tableSmallPadding: "8px",
        tableBigPadding: "24px",
        smallPadding: "8px",
        mainPadding: "16px",
        bigPadding: "24px",
        mainGap: "8px",
        smallGap: "4px",
        largeGap: "16px",
      },
      colors: {
        white: "#fff",
        black: "#242726",
        "black-70": "rgba(33, 33, 33, 0.7);",

        grey100_1: "#c3c3c819",
        grey100_4: "#c3c3c866",
        grey400: "#404342",
        grey300: "#5C6763",
        grey250: "#9B9B9B",
        grey200: "#A1AAA8",
        grey100: "#C3C3C8",
        grey75: "#E1E1E6",
        grey50: "#F3F3F3",
        grey25: "#F6F6F4",

        green400_1: "#29554a19",
        green400_3: "#29554a4c",
        green400: "#29554A",
        green300: "#79A398",
        green200: "#1D9C7C",
        green100: "#80CEBA",
        green75: "#ACCCC6",
        green50: "#D6E0DE",
        "green50-10": "rgba(214, 224, 222, 0.1);",
        "green50-20": "rgba(214, 224, 222, 0.2);",

        blue200: "#309CFF",
        blue100: "#83C3FF",

        yellow200: "#FFE600",
        yellow100: "#FFF387",

        red200: "#FF531A",
        red100: "#FF9F8A",
        red50: "#FFEEE9",

        pink200: "#FF5592",
        pink100: "#FF99BE",

        emerald200: "#30A33B",
        emerald100: "#E3F3E5",
        emerald50: "#F6FAF6",

        orange200: "#F6A300",
        orange100: "#FCEDD0",
        orange50: "#FFFAF0",

        primary: "$green400",

        text: "$black",
        textGhost: "$grey200",
        textSecondary: "$grey300",
        textWarning: "$orange200",
        textSuccess: "$emerald200",
        textError: "$red200",
        bgBody: "$grey25",
        anchor: "$black",
        main: "$white",

        btnBg: "$primary",
        btnBgHover: "$green300",
        btnColor: "$white",
        btnColorHover: "$white",
        btnBgDisabled: "$grey200",
        btnColorDisabled: "$white",

        btnSecondaryBg: "$green50-10",
        btnSecondaryBgHover: "$green300",
        btnSecondaryColor: "$green400",
        btnSecondaryColorHover: "$white",
        btnSecondaryBgDisabled: "$grey200",
        btnSecondaryColorDisabled: "$white",

        btnGhostBg: "transparent",
        btnGhostBorderColor: "$grey200",
        btnGhostBgHover: "$grey200",
        btnGhostColor: "$grey300",
        btnGhostColorHover: "$green400",
        btnGhostBgDisabled: "$grey200",
        btnGhostColorDisabled: "$grey200",

        btnDangerBg: "$red200",

        inputBorderColor: "$grey75",
        inputBorderColorFocus: "$green400",
      },
      radii: {
        smallBr: "4px",
        mainBr: "8px",
        mainMobileBr: "8px",
        sidebarBr: "30px",
        fieldBr: "6px",
        fieldCircleBr: "30px",
        btnBr: "24px",
        dataCardBr: "6px",
        btnSmallBr: "8px",
      },
      fonts: {
        fontBody: "sans-serif",
        raleway: '"Raleway"',
        plexMono: '"IBM Plex Mono"',
        metricFont: "$plexMono, monospace",
        fontDisplay: "$raleway, $fontBody",
      },
      fontWeights: {
        light: 300,
        regular: 400,
        medium: 500,
        semiBold: 600,
        bold: 700,
      },
      fontSizes: {
        xxs: "10px",
        xs: "12px",
        s: "14px",
        m: "16px",
        l: "20px",
        xl: "24px",
        xxl: "28px",
        xxxl: "32px",

        d2: "44px",
        d3: "40px",
      },
      lineHeights: {
        xxs: "14px",
        xs: "16px",
        s: "20px",
        m: "22px",
        l: "24px",
        xl: "28px",
        xxl: "32px",
        xxxl: "36px",

        d2: "48px",
        d3: "44px",
      },
      shadows: {
        tableFixedColShadowL: "3px 0px 6px rgba(36, 39, 38, 0.1)",
        tableFixedColShadowR: "-3px 0px 6px rgba(36, 39, 38, 0.1)",
        tableStatusBoxShadow: "inset 4px 0px 0px transparent",
        redStatusBoxShadow: "inset 4px 0px 0px #FF531A",
        orangeStatusBoxShadow: "inset 4px 0px 0px #F6A300",
      },
    },
    media: {
      // xs: '(min-width: 375px)',
      s: "(min-width: 768px)",
      m: "(min-width: 1024px)",
      l: "(min-width: 1440px)",
      xl: "(min-width: 1920px)",
    },
    utils: {
      sz: (value: string) => ({
        width: value,
        height: value,
      }),
      px: (value: string) => ({
        paddingLeft: value,
        paddingRight: value,
      }),
      py: (value: string) => ({
        paddingTop: value,
        paddingBottom: value,
      }),
      mx: (value: string) => ({
        marginLeft: value,
        marginRight: value,
      }),
      my: (value: string) => ({
        marginTop: value,
        marginBottom: value,
      }),
    },
  });

export const darkTheme = createTheme("dark", {
  colors: {
    white: "#fff",

    grey: "#8883A6",
    greyLight: "#4F4B6C",
    greyLightest: "#29244B",
    greyDark: "#D1CDEA",

    purpleHover: "$purpleDark",
    blueHover: "$blueDark",
    greenHover: "$greenDark",
    yellowHover: "$yellowDark",
    orangeHover: "$orangeDark",
    pinkHover: "$pinkDark",

    text: "$white",
    bgBody: "$greyLightest",
    anchor: "$white",
    main: "$greyDarkest",
    btnBg: "$purple",
    btnBgHover: "$purpleDark",
  },
});

export const globalStyles = globalCss({
  "@font-face": [
    {
      fontFamily: "Raleway",
    },
    {
      fontFamily: '"IBM Plex Mono"',
    },
  ],
  '@import': [
    "url('https://fonts.googleapis.com/css2?family=IBM+Plex+Mono:wght@300;400;500;600;700&family=Raleway:wght@300;400;500;600;700&display=swap')",
  ],

  body: {
    margin: "0",
    color: "$text",
    backgroundColor: "$bgBody",
    fontFamily: "$fontDisplay",
    fontWeight: "$regular",
    fontSize: "$s",
    lineHeight: `$s`,
    fontFeatureSettings: '"pnum" on, "lnum" on',
  },

  button: {
    border: 0,
    padding: 0,
    margin: 0,
    cursor: "pointer",
    background: "none",
    fontFamily: "$fontDisplay",
    fontWeight: "$regular",
  },

  a: {
    textDecoration: "none",
  },

  'input[type="search"]::-webkit-search-decoration': {
    "-webkit-appearance": "none",
  },
  'input[type="search"]::-webkit-search-cancel-button': {
    "-webkit-appearance": "none",
  },
  'input[type="search"]::-webkit-search-results-button': {
    "-webkit-appearance": "none",
  },
  'input[type="search"]::-webkit-search-results-decoration': {
    "-webkit-appearance": "none",
  },
});
