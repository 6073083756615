import { Button, Text } from "@/uikit";
import { styled } from "stitches.config";

export const StyledRecoveryPasswordWrapper = styled("div", {
  width: "100%",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "flex-start",
});

export const StyledRecoveryPasswordText = styled(Text, {
  fontStyle: "normal",
  fontWeight: "400",
  fontSize: "14px",
  lineHeight: "20px",
  textAlign: "center",
  color: "$black",
});

export const StyledRecoveryPasswordTextLink = styled("a", {
  fontStyle: "normal",
  fontWeight: "700",
  fontSize: "14px",
  lineHeight: "20px",
  cursor: "pointer",
  textAlign: "center",
  color: "$black",
});

export const StyledRecoveryPasswordButton = styled(Button, {
  marginTop: "56px",
  width: "100%",
});
