import React from "react";
import IdentityContent from "@/components/identityContent";
import { Button } from "@/uikit";

interface IResetPassword {
  onSubmit: () => void;
}

const ResetPassword: React.FC<IResetPassword> = ({ onSubmit }) => {
  return (
    <IdentityContent
      title="Сброс пароля"
      subTitle="Вы успешно изменили пароль, теперь можете авторизоваться"
    >
      <Button kind="primary" size="small" isBlock={true} onClick={onSubmit}>
        Понятно
      </Button>
    </IdentityContent>
  );
};

export default ResetPassword;
