import * as ToggleGroupPrimitive from "@radix-ui/react-toggle-group";
import React from "react";
import { toggleStyles } from "../styles";
import { styled } from "stitches.config";

const StyledToggleGroup = styled(ToggleGroupPrimitive.Root, {
  display: "inline-flex",
  background: "$grey25",
  borderRadius: "$mainBr",
  padding: "4px",
});

const StyledToggleGroupItem = styled(ToggleGroupPrimitive.Item, toggleStyles);

export const ToggleGroup = StyledToggleGroup;
export const ToggleGroupItem = StyledToggleGroupItem;
