import { styled } from "stitches.config";
import type * as Stitches from "@stitches/react";
import React from "react";

export const GridContainer = styled("div", {
  display: "grid",
  gridTemplateColumns: "repeat(12, 1fr)",
  gridAutoFlow: "dense",
});

export const GridElement = styled("div", {
  variants: {
    row: {
      1: {
        gridRow: "span 1",
      },
      2: {
        gridRow: "span 2",
      },
      3: {
        gridRow: "span 3",
      },
      4: {
        gridRow: "span 4",
      },
      5: {
        gridRow: "span 5",
      },
      6: {
        gridRow: "span 6",
      },
      7: {
        gridRow: "span 7",
      },
      8: {
        gridRow: "span 8",
      },
      9: {
        gridRow: "span 9",
      },
      10: {
        gridRow: "span 10",
      },
      11: {
        gridRow: "span 11",
      },
      12: {
        gridRow: "span 12",
      },
    },
    col: {
      1: {
        gridColumnEnd: "span 1",
      },
      2: {
        gridColumnEnd: "span 2",
      },
      3: {
        gridColumnEnd: "span 3",
      },
      4: {
        gridColumnEnd: "span 4",
      },
      5: {
        gridColumnEnd: "span 5",
      },
      6: {
        gridColumnEnd: "span 6",
      },
      7: {
        gridColumnEnd: "span 7",
      },
      8: {
        gridColumnEnd: "span 8",
      },
      9: {
        gridColumnEnd: "span 9",
      },
      10: {
        gridColumnEnd: "span 10",
      },
      11: {
        gridColumnEnd: "span 11",
      },
      12: {
        gridColumnEnd: "span 12",
      },
    },
  },
});

type StyledButtonVariants = Stitches.VariantProps<typeof GridElement>;

export const Grid: React.FC<
  StyledButtonVariants & { fillRow?: boolean; children?: React.ReactNode }
> = ({ row, col, fillRow, children }) => {
  return (
    <>
      <GridElement row={row} col={col}>
        {children}
      </GridElement>
      {fillRow && (
        <GridElement
          row={row}
          col={(12 - Number(col)) as StyledButtonVariants["col"]}
        ></GridElement>
      )}
    </>
  );
};
