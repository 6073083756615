import React, { useEffect } from "react";
import { useConsentChallenge } from "@/ory/hooks";

const Consent: React.FC = () => {
  const { handleConsentChallenge } = useConsentChallenge();

  useEffect(() => {
    handleConsentChallenge();
  });

  return <React.Fragment />;
};

export default Consent;
