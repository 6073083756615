import * as TogglePrimitive from "@radix-ui/react-toggle";
import React, { RefObject } from "react";
import { toggleStyles } from "../styles";
import { styled } from "stitches.config";

export type ToggleProps = {
  name: string;
  label?: string;
  value?: boolean;
  inputRef?: RefObject<HTMLInputElement>;
};

const StyledToggle = styled(TogglePrimitive.Root, toggleStyles);

export const Toggle = StyledToggle;
