import { CheckIcon } from "@radix-ui/react-icons";
import React from "react";
import { styled } from "stitches.config";

export interface ICheckbox {
  value?: string;
  checked?: boolean;
  onChange?: () => void;
  name?: string;
  id?: string;
  label?: string;
  isDisabled?: boolean;
}

const StyledInput = styled("input", {
  height: 0,
  width: 0,
  opacity: 0,
  zIndex: -1,
});

const StyledLabel = styled("label", {
  position: "relative",
  display: "inline-block",
  cursor: "pointer",
  paddingLeft: "32px",
  fontStyle: "normal",
  fontWeight: "400",
  fontSize: "14px",
  lineHeight: "20px",
  color: "$black",
  variants: {
    isDisabled: {
      true: {
        cursor: "not-allowed",
        color: "$grey100_4",
      },
    },
  },
});

const StyledIndicator = styled("div", {
  width: "24px",
  height: "24px",
  transition: " all 0.5s ease",
  position: "absolute",
  top: "50%",
  left: 0,
  transform: "translate(0%, -50%)",
  borderRadius: "4px",
  background: "$green400_1",

  "& svg": {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    opacity: 0,
    transition: "all 0.5s ease",
  },

  [`${StyledInput}:checked + & svg`]: {
    opacity: 1,
  },

  [`& ${StyledInput}:not(:disabled):checked &`]: {
    background: "$green400_1",
  },

  "&:disabled": {
    cursor: "not-allowed",
  },

  variants: {
    isDisabled: {
      true: {
        background: "$grey100_1",
      },
    },
  },

  [`${StyledLabel}:hover &`]: {
    variants: {
      isDisabled: {
        background: "$grey100_1",
      },
    },
  },
});

export const Checkbox: React.FC<ICheckbox> = React.memo(
  ({ value, checked, onChange, name, id, label, isDisabled, ...props }) => {
    return (
      <StyledLabel htmlFor={id} isDisabled={isDisabled}>
        {label}
        <StyledInput
          id={id}
          type="checkbox"
          name={name}
          value={value}
          disabled={isDisabled}
          checked={checked}
          onChange={onChange}
          {...props}
        />
        <StyledIndicator isDisabled={isDisabled}>
          <CheckIcon />
        </StyledIndicator>
      </StyledLabel>
    );
  }
);

Checkbox.displayName = "Checkbox";
