import { Root } from "@radix-ui/react-separator";
import { styled } from "stitches.config";

const StyledSeparator = styled(Root, {
  border: "1px solid $grey75",
  width: "100%",
  margin: "16px 0",
});

export const Separator = () => <StyledSeparator />;
