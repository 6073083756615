import React, { ReactNode, useCallback } from "react";
import {
  Root,
  Content,
  Arrow,
  Trigger,
  TooltipContentProps,
  Provider,
} from "@radix-ui/react-tooltip";
import { keyframes, styled } from "stitches.config";

const scaleIn = keyframes({
  "0%": { opacity: 0, transform: "scale(0)" },
  "100%": { opacity: 1, transform: "scale(1)" },
});

const StyledContent = styled(Content, {
  backgroundColor: "$white",
  color: "$black",
  padding: "$tooltipPadding",
  borderRadius: "$mainBr",
  maxWidth: "$tooltipMaxWidth",
  boxShadow: "0px 8px 20px rgba(0, 0, 0, 0.1)",
  transformOrigin: "var(--radix-tooltip-content-transform-origin)",
  animation: `${scaleIn} 0.1s ease-out`,
});

const StyledArrow = styled(Arrow, {
  fill: "$white",
});

const Overlay = styled("div", {
  position: "fixed",
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  backgroundColor: "transparent",
  zIndex: 1000,
});

export const Tooltip: React.FC<{
  children?: React.ReactNode;
  content: string | number | JSX.Element | ReactNode;
  defaultOpen?: boolean;
  triggerOnHover?: boolean;
  hideTimeout?: number;
  // TODO: Add more props for arrow and content options and stories
  options?: TooltipContentProps;
}> = ({
  children,
  content,
  defaultOpen = false,
  triggerOnHover = false,
  hideTimeout,
  options = {},
}) => {
  const [isOpen, setIsOpen] = React.useState(defaultOpen);

  const showTooltip = useCallback(() => {
    setIsOpen(true);
  }, []);

  const hideTooltip = useCallback(() => {
    setIsOpen(false);
  }, []);

  const toggleTooltip = () => {
    setIsOpen((isOpen) => !isOpen);
    if (hideTimeout) setTimeout(() => hideTooltip(), hideTimeout);
  };

  const callbacks = triggerOnHover
    ? {
        onMouseEnter: showTooltip,
        onMouseLeave: hideTooltip,
      }
    : {
        onClick: toggleTooltip,
      };

  return (
    <Provider delayDuration={0}>
      <Root open={isOpen}>
        <Trigger {...callbacks} asChild>
          {children}
        </Trigger>
        {isOpen && !triggerOnHover && <Overlay onClick={hideTooltip} />}
        <StyledContent align="center" {...options}>
          {content}
          <StyledArrow offset={10} width={10} height={6} />
        </StyledContent>
      </Root>
    </Provider>
  );
};

Tooltip.displayName = "Tooltip";
