import { Title, Text } from "@/uikit";
import { styled } from "@stitches/react";

export const StyledModalPasswordConfirmWrapper = styled("div", {
  width: "100%",
  display: "grid",
  gridTemplateColumns: "1fr",
  gap: "32px",
});

export const StyledModalPasswordConfirmTitle = styled(Title, {
  color: "$black",
});

export const StyledModalPasswordConfirmSubTitle = styled(Text, {
  width: "100%",
});
