export const HidePassIcon = () => (
  <svg
    width="12"
    height="5"
    viewBox="0 0 12 5"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.3895 0.0361142C11.4996 0.105598 11.533 0.25205 11.4642 0.363224C11.114 0.92919 10.6929 1.43937 10.2114 1.87621L11.2188 2.89358C11.3106 2.98628 11.3106 3.13658 11.2188 3.22928C11.127 3.32199 10.9782 3.32199 10.8864 3.22928L9.84933 2.18191C9.20418 2.68789 8.46671 3.06974 7.65833 3.29197L8.03216 4.70113C8.06576 4.82776 7.99134 4.95792 7.86596 4.99185C7.74057 5.02578 7.61169 4.95063 7.5781 4.82399L7.2001 3.39916C6.81373 3.47404 6.41298 3.51325 6 3.51325C5.58701 3.51325 5.18626 3.47404 4.79988 3.39916L4.42192 4.82399C4.38832 4.95063 4.25945 5.02578 4.13406 4.99185C4.00867 4.95793 3.93426 4.82777 3.96785 4.70113L4.34166 3.29197C3.53327 3.06973 2.7958 2.68788 2.15065 2.1819L1.11359 3.22928C1.0218 3.32198 0.872981 3.32198 0.781191 3.22928C0.689402 3.13657 0.689402 2.98627 0.781191 2.89357L1.78854 1.8762C1.30712 1.43936 0.886 0.929183 0.535758 0.363224C0.466959 0.25205 0.500422 0.105598 0.610501 0.0361146C0.720579 -0.0333693 0.865588 0.000426952 0.934387 0.111601C1.30348 0.708022 1.75492 1.23603 2.27468 1.6736C2.28063 1.67812 2.28642 1.68297 2.29202 1.68814C3.30495 2.53452 4.57526 3.03849 6 3.03849C8.16608 3.03849 9.9752 1.87361 11.0656 0.1116C11.1344 0.000426598 11.2794 -0.0333696 11.3895 0.0361142Z"
      fill="currentColor"
    />
  </svg>
);
