import { AxiosError } from "@ory/hydra-client/node_modules/axios";
import { useState, useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { hydra, kratos } from "@/ory/helpers";
import { handleFlowError } from "@/ory/errors";
import {
  LoginFlow,
  RecoveryFlow,
  RegistrationFlow,
  SettingsFlow,
  UiContainer,
  UiNodeInputAttributes,
  UpdateLoginFlowWithPasswordMethod,
  UpdateRegistrationFlowWithPasswordMethod,
  UpdateSettingsFlowWithPasswordMethod,
  UpdateSettingsFlowWithProfileMethod,
  VerificationFlow,
} from "@ory/kratos-client";
import { OAuth2ConsentRequest, OAuth2LoginRequest } from "@ory/hydra-client";
import { useGlobalState } from "@/contexts/GlobalContext";
import { INodeError } from "@/helpers/interfaces";
const CONSOLE_LINK = process.env.REACT_APP_CONSOLE_URL;

export const useConsentChallenge = () => {
  const [globalState] = useGlobalState();
  const [searchParams] = useSearchParams();
  const challengeID = searchParams.get("consent_challenge");
  const [challenge, setChallenge] = useState<OAuth2ConsentRequest>();

  useEffect(() => {
    if (!challengeID || challenge) return;

    hydra
      .getOAuth2ConsentRequest(challengeID)
      .then(({ data }) => {
        setChallenge(data);
      })
      .catch((err: AxiosError) => {
        return Promise.reject(err);
      });
  }, [challengeID]);

  const handleConsentChallenge = () => {
    if (!challenge || !globalState.identity) return;

    hydra
      .acceptOAuth2ConsentRequest(challenge.challenge, {
        grant_scope: challenge.requested_scope,
        grant_access_token_audience: challenge.requested_access_token_audience,
        session: {
          access_token: {
            ...globalState.identity.traits,
          },
        },
      })
      .then(({ data }) => {
        window.location.href = data.redirect_to;
      })
      .catch((err: AxiosError) => {
        return Promise.reject(err);
      });
  };

  return { handleConsentChallenge };
};

export const useLoginChallenge = () => {
  const [globalState] = useGlobalState();
  const [searchParams] = useSearchParams();
  const challengeID = searchParams.get("login_challenge");
  const [challenge, setChallenge] = useState<OAuth2LoginRequest>();

  useEffect(() => {
    if (!challengeID || challenge || !globalState.identity) return;

    hydra
      .getOAuth2LoginRequest(challengeID)
      .then(({ data }) => {
        setChallenge(data);
      })
      .catch((err: AxiosError) => {
        return Promise.reject(err);
      });
  }, [challengeID, globalState.identity, challenge]);

  useEffect(() => {
    if (!challenge || !globalState.identity) return;

    hydra
      .acceptOAuth2LoginRequest(challenge.challenge, {
        subject: globalState.identity.id,
      })
      .then(({ data }) => {
        window.location.href = data.redirect_to;
      })
      .catch((err: AxiosError) => {
        return Promise.reject(err);
      });
  }, [challenge, globalState.identity]);

  return { challenge };
};

export const isLoginChallenge = () => {
  const [searchParams] = useSearchParams();
  return searchParams.has("login_challenge");
};

export const useSession = () => {
  const [, setGlobalState] = useGlobalState();
  const [isLoading, setIsLoading] = useState(true);

  const handleGetSession = () => {
    setIsLoading(true);

    kratos
      .toSession()
      .then(({ data }) => {
        setGlobalState((prevState) => ({
          ...prevState,
          identity: data.identity,
        }));
      })
      .catch((err: AxiosError) => {
        return Promise.reject(err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return { handleGetSession, isLoading };
};

export function useLogoutHandler() {
  const [, setGlobalState] = useGlobalState();
  const navigate = useNavigate();

  const handleLogout = async () => {
    try {
      const { data } = await kratos.createBrowserLogoutFlow();
      const logoutToken = data.logout_token;

      if (logoutToken) {
        await kratos.updateLogoutFlow({ token: logoutToken });
        await setGlobalState((prevState) => ({
          ...prevState,
          identity: null,
          isVerificationRequired: false,
        }));
        await navigate("/login");
      }
    } catch (err) {
      switch ((err as AxiosError).response?.status) {
        case 401:
          // do nothing, the user is not logged in
          return;
      }
    }
  };

  return { handleLogout };
}

export function useLoginHandler() {
  const navigate = useNavigate();
  const isLoginChallengeRuns = isLoginChallenge();
  const [, setGlobalState] = useGlobalState();
  const [isPending, setIsPending] = useState(false);
  const [error, setError] = useState<string | INodeError>("");
  const [searchParams] = useSearchParams();
  const [flow, setFlow] = useState<LoginFlow>();
  const [token, setToken] = useState("");
  const returnTo = searchParams.get("return_to");
  const flowId = searchParams.get("flow");
  const refresh = searchParams.get("refresh");
  const aal = searchParams.get("aal");
  const { handleGetSession } = useSession();

  useEffect(() => {
    if (flow) {
      return;
    }

    kratos
      .createBrowserLoginFlow({
        refresh: Boolean(refresh),
        aal: aal ? String(aal) : undefined,
        returnTo: returnTo ? String(returnTo) : undefined,
      })
      .then(({ data }) => {
        setFlow(data);
      })
      .catch(handleFlowError("login", setFlow, setError, navigate));
  }, [flowId, aal, refresh, returnTo, flow]);

  useEffect(() => {
    if (!flow) return;

    const csrfToken = (
      flow.ui?.nodes?.find(
        (node) =>
          (node.attributes as UiNodeInputAttributes).name === "csrf_token"
      )?.attributes as UiNodeInputAttributes
    )?.value;

    if (csrfToken) {
      setToken(csrfToken);
    }
  }, [flow]);

  const handleLogin = (data: UpdateLoginFlowWithPasswordMethod) => {
    setIsPending(true);

    console.log("Prepare login");
    console.log("flow", flow?.id);
    console.log("updateLoginFlowBody", data);

    kratos
      .updateLoginFlow({ flow: String(flow?.id), updateLoginFlowBody: data })
      .then(handleGetSession)
      .then(() => {
        if (!isLoginChallengeRuns)
          window.location.href = returnTo || (CONSOLE_LINK as string);
      })
      .catch(handleFlowError("registration", setFlow, setError, navigate))
      .catch((err: AxiosError<LoginFlow>) => {
        console.error("Fail login", err);
        if (err.response?.status === 400) {
          if (
            err.response?.data?.ui?.messages &&
            Object.values(err.response?.data?.ui?.messages)?.find(
              (message) => message.id === 4000010
            )
          ) {
            setGlobalState((prevState) => ({
              ...prevState,
              isVerificationRequired: true,
            }));
            navigate("/verification", {
              state: { email: data.identifier },
            });
          }
          return;
        }

        return Promise.reject(err);
      })
      .finally(() => {
        setIsPending(false);
      });
  };

  const resetErrors = () => {
    setError("");
    setGlobalState((prevState) => ({
      ...prevState,
      isVerificationRequired: true,
    }));
  };

  return {
    token,
    isPending,
    error,
    setError,
    handleLogin,
    resetErrors,
  };
}

export function useRegistrationHandler() {
  const navigate = useNavigate();
  const [isPending, setIsPending] = useState(false);
  const [error, setError] = useState<string | INodeError>("");
  const [searchParams] = useSearchParams();
  const [flow, setFlow] = useState<RegistrationFlow>();
  const [token, setToken] = useState("");
  const [isEmailSent, setIsEmailSent] = useState(false);
  const returnTo = searchParams.get("return_to");

  useEffect(() => {
    if (flow) {
      return;
    }

    kratos
      .createBrowserRegistrationFlow({
        returnTo: returnTo ? String(returnTo) : undefined,
      })
      .then(({ data }) => {
        setError("");
        setFlow(data);
      })
      .catch(handleFlowError("registration", setFlow, setError, navigate));
  }, [returnTo, flow]);

  useEffect(() => {
    if (!flow) return;

    const csrfToken = (
      flow.ui?.nodes?.find(
        (node) =>
          (node.attributes as UiNodeInputAttributes).name === "csrf_token"
      )?.attributes as UiNodeInputAttributes
    )?.value;

    if (csrfToken) {
      setToken(csrfToken);
    }
  }, [flow]);

  const handleRegistration = (
    data: UpdateRegistrationFlowWithPasswordMethod
  ) => {
    setIsPending(true);

    kratos
      .updateRegistrationFlow({
        flow: String(flow?.id),
        updateRegistrationFlowBody: data,
      })
      .then(() => {
        setIsEmailSent(true);
        setError("");
      })
      .catch(handleFlowError("registration", setFlow, setError, navigate))
      .finally(() => {
        setIsPending(false);
      });
  };

  return {
    token,
    isPending,
    isEmailSent,
    error,
    setError,
    setIsEmailSent,
    handleRegistration,
  };
}

export const useVerificationHandler = () => {
  const [flow, setFlow] = useState<VerificationFlow>();
  const [token, setToken] = useState("");
  const [email, setEmail] = useState("");
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const returnTo = searchParams.get("return_to");
  const flowId = searchParams.get("flow");

  useEffect(() => {
    if (!flow) return;

    const csrfToken = (
      flow.ui?.nodes?.find(
        (node) =>
          (node.attributes as UiNodeInputAttributes).name === "csrf_token"
      )?.attributes as UiNodeInputAttributes
    )?.value;

    if (csrfToken) {
      setToken(csrfToken);
    }
  }, [flow]);

  const createVerificationSession = async () => {
    if (flowId) {
      try {
        const { data } = await kratos.getVerificationFlow({
          id: String(flowId),
        });
        setFlow(data);
      } catch (err) {
        switch ((err as AxiosError).response?.status) {
          case 410:
          // Status code 410 means the request has expired - so let's load a fresh flow!
          case 403:
            // Status code 403 implies some other issue (e.g. CSRF) - let's reload!
            return navigate("/verification");
        }

        throw err;
      }
      return;
    }

    kratos
      .createBrowserVerificationFlow({
        returnTo: returnTo ? String(returnTo) : undefined,
      })
      .then(({ data }) => {
        setFlow(data);
      })
      .catch((err: AxiosError) => {
        switch (err.response?.status) {
          case 400:
            // Status code 400 implies the user is already signed in
            window.location.href = returnTo || (CONSOLE_LINK as string);
        }

        throw err;
      });
  };

  useEffect(() => {
    if (!flow || !email || !token) return;

    const asyncHandler = async () => {
      if (!flow?.ui) return;

      try {
        await kratos.updateVerificationFlow({
          flow: String(flow?.id),
          updateVerificationFlowBody: {
            csrf_token: token,
            email,
            method: "link",
          },
        });
      } catch (err) {
        switch ((err as AxiosError).response?.status) {
          case 400:
            // Status code 400 implies the form validation had an error
            setFlow((err as AxiosError).response?.data as VerificationFlow);
            return;
        }

        throw err;
      }
    };

    asyncHandler();
  }, [flow, email, token]);

  const resendEmail = async (email: string) => {
    setEmail(email);
    await createVerificationSession();
  };

  return { resendEmail };
};

export const useRecoveryHandler = () => {
  const [flow, setFlow] = useState<RecoveryFlow>();
  const [token, setToken] = useState("");
  const [email, setEmail] = useState("");
  const [searchParams] = useSearchParams();
  const [isRecoverySent, setIsRecoverySent] = useState(false);
  const [isPending, setIsPending] = useState(false);
  const flowId = searchParams.get("flow");

  const createRecoverySession = async () => {
    setIsPending(true);

    if (flowId) {
      try {
        const { data } = await kratos.getRecoveryFlow({ id: String(flowId) });
        setFlow(data);
      } catch (err) {
        if (!flow) setIsPending(false);
      }
      return;
    }

    try {
      const { data } = await kratos.createBrowserRecoveryFlow();
      setFlow(data);
    } catch (err) {
      if ((err as AxiosError).response?.status === 400) {
        setFlow((err as AxiosError<RecoveryFlow>).response?.data);
        return;
      }
      if (!flow) setIsPending(false);
      return Promise.reject(err);
    }
  };

  useEffect(() => {
    if (!flow) return;

    const csrfToken = (
      flow.ui?.nodes?.find(
        (node) =>
          (node.attributes as UiNodeInputAttributes).name === "csrf_token"
      )?.attributes as UiNodeInputAttributes
    )?.value;

    if (csrfToken) {
      setToken(csrfToken);
    }
  }, [flow]);

  useEffect(() => {
    if (!flow || !token || !email) return;

    const asyncExecutor = async () => {
      try {
        await kratos.updateRecoveryFlow({
          flow: String(flow?.id),
          updateRecoveryFlowBody: {
            csrf_token: token,
            email,
            method: "link",
          },
        });
        setIsRecoverySent(true);
      } catch (err) {
        switch ((err as AxiosError).response?.status) {
          case 400:
            setFlow((err as AxiosError<RecoveryFlow>).response?.data);
            return;
        }

        throw err;
      } finally {
        setIsPending(false);
      }
    };

    asyncExecutor();
  }, [token, email]);

  const recoveryPassword = async (email: string) => {
    setEmail(email);
    await createRecoverySession();
  };

  return { recoveryPassword, isRecoverySent, isPending };
};

export const useSettingsHandler = () => {
  const [globalState] = useGlobalState();
  const [flow, setFlow] = useState<SettingsFlow>();
  const [isPending, setIsPending] = useState(false);
  const [token, setToken] = useState("");
  const [error, setError] = useState<string | INodeError>("");
  const [searchParams] = useSearchParams();
  const flowId = searchParams.get("flow");
  const { handleGetSession } = useSession();
  const [isOutdated, setIsOutdated] = useState(false);

  useEffect(() => {
    if (flow) {
      return;
    }

    const asyncExecutor = async () => {
      if (flowId) {
        try {
          const { data } = await kratos.getSettingsFlow({ id: String(flowId) });
          setFlow(data);
        } catch (err) {
          console.log(err);
          setIsOutdated(true);
        }
        return;
      }

      try {
        const { data } = await kratos.createBrowserSettingsFlow();
        setFlow(data);
      } catch (err) {
        if ((err as AxiosError).response?.status === 400) {
          setFlow((err as AxiosError<SettingsFlow>).response?.data);
          return;
        }
      }
    };

    asyncExecutor();
  }, [flow]);

  useEffect(() => {
    if (!flow) return;

    const csrfToken = (
      flow.ui?.nodes?.find(
        (node) =>
          (node.attributes as UiNodeInputAttributes).name === "csrf_token"
      )?.attributes as UiNodeInputAttributes
    )?.value;

    if (csrfToken) {
      setToken(csrfToken);
    }
  }, [flow]);

  const handleSubmit = async (
    field: string,
    method: "password" | "profile"
  ) => {
    setIsPending(true);

    const settings:
      | UpdateSettingsFlowWithPasswordMethod
      | UpdateSettingsFlowWithProfileMethod =
      method === "password"
        ? {
            csrf_token: token,
            method: "password",
            password: field,
          }
        : {
            csrf_token: token,
            method: "profile",
            traits: {
              ...globalState.identity?.traits,
              email: field,
            },
          };

    try {
      const { data } = await kratos.updateSettingsFlow({
        flow: String(flow?.id),
        updateSettingsFlowBody: settings,
      });
      await handleGetSession();
      setFlow(data);
    } catch (err) {
      await handleFlowError(
        "registration",
        setFlow,
        setError
      )(err as AxiosError<{ ui: UiContainer }>);
      switch ((err as AxiosError).response?.status) {
        case 400:
          setFlow((err as AxiosError<SettingsFlow>).response?.data);
          return;
      }

      throw err;
    } finally {
      setIsPending(false);
    }
  };

  return { isPending, error, isOutdated, setError, handleSubmit };
};
