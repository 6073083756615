import {
  Content,
  List,
  Root,
  Trigger,
  TabsProps as RawProps,
} from "@radix-ui/react-tabs";
import { useMemo } from "react";
import { styled } from "stitches.config";

type TabItem = {
  label: string;
  content: string | JSX.Element;
};

type TabsItems = { [key: string]: TabItem };

export type TabsProps = {
  items: TabsItems;
  defaultValue?: keyof TabsItems;
  orientation?: RawProps["orientation"];
};

const StyledRoot = styled(Root, {
  borderRadius: "$mainBr",
  padding: "0 16px",
  background: "$white",
});

const StyledTrigger = styled(Trigger, {
  borderBottom: "2px solid transparent",

  '&[data-state="active"]': {
    color: "$primary",
    borderBottom: "2px solid $primary",
  },
});

const LabelWrapper = styled("div", {
  padding: "20px 0",
});

const StyledList = styled(List, {
  display: "flex",
  gap: "32px",
  position: "relative",
  boxShadow: "inset 0 -1px #f3f3f3",
});

const StyledContent = styled(Content, {
  padding: "24px 0",
});

const TabsTrigger: React.FC<{ value: string; label: string }> = ({
  value,
  label,
}) => (
  <StyledTrigger value={value}>
    <LabelWrapper>{label}</LabelWrapper>
  </StyledTrigger>
);

const Tabs: React.FC<TabsProps> = ({
  items,
  defaultValue,
  orientation = "horizontal",
}) => {
  const defaultTab = useMemo(
    () => (defaultValue as string) || Object.keys(items)[0],
    [defaultValue, items]
  );
  const itemsEntries = useMemo(() => Object.entries(items), [items]);

  return (
    <StyledRoot defaultValue={defaultTab} orientation={orientation}>
      <StyledList aria-label="tabs">
        {itemsEntries.map(([key, { label }]) => (
          <TabsTrigger key={key} value={key} label={label} />
        ))}
      </StyledList>
      {itemsEntries.map(([key, { content }]) => (
        <StyledContent key={key} value={key}>
          {content}
        </StyledContent>
      ))}
    </StyledRoot>
  );
};

export {
  Tabs,
  StyledRoot as TabsRoot,
  StyledTrigger as TabsTrigger,
  StyledList as TabsList,
  StyledContent as TabsContent,
};
