export const TokenIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
  >
    <path
      d="M2.27685 9.72898C2.27685 13.9308 5.32763 16.7909 8.7406 17.3819C8.97695 17.4228 9.13538 17.6476 9.09445 17.884C9.05353 18.1203 8.82875 18.2787 8.5924 18.2378C4.81847 17.5844 1.4082 14.4072 1.4082 9.72898C1.4082 7.76543 2.30003 6.21432 3.36313 5.01808C4.25203 4.01787 5.28793 3.23793 6.04829 2.66545C6.14384 2.59352 6.23522 2.52472 6.32122 2.45931L4.20922 2.45931C3.97911 2.45931 3.79256 2.27276 3.79256 2.04264C3.79256 1.81253 3.97911 1.62598 4.20922 1.62598L7.37589 1.62598C7.60601 1.62598 7.79256 1.81252 7.79256 2.04264L7.79256 5.20931C7.79256 5.43943 7.60601 5.62598 7.37589 5.62598C7.14577 5.62598 6.95923 5.43943 6.95923 5.20931L6.95923 3.06495C6.83276 3.16228 6.69748 3.26426 6.55549 3.37131C5.79361 3.94569 4.83805 4.66609 4.01243 5.59511C3.03425 6.69579 2.27685 8.04997 2.27685 9.72898Z"
      fill="currentColor"
    />
    <path
      d="M17.6082 10.2709C17.6082 6.11354 14.6221 3.27003 11.253 2.63755C11.0173 2.59329 10.862 2.3663 10.9063 2.13054C10.9506 1.89479 11.1775 1.73956 11.4133 1.78382C15.1387 2.48321 18.4769 5.64263 18.4769 10.2709C18.4769 12.2344 17.585 13.7855 16.5219 14.9818C15.633 15.982 14.5971 16.762 13.8368 17.3344C13.7413 17.4063 13.6498 17.4752 13.5639 17.5406H15.6759C15.906 17.5406 16.0926 17.7271 16.0926 17.9572C16.0926 18.1873 15.906 18.3739 15.6759 18.3739H12.5092C12.2791 18.3739 12.0926 18.1873 12.0926 17.9572V14.7906C12.0926 14.5604 12.2791 14.3739 12.5092 14.3739C12.7393 14.3739 12.9259 14.5604 12.9259 14.7906V16.9349C13.0524 16.8375 13.1877 16.7355 13.3297 16.6285C14.0916 16.0541 15.047 15.3338 15.8726 14.4048C16.8508 13.3041 17.6082 11.9499 17.6082 10.2709Z"
      fill="currentColor"
    />
    <path
      d="M12.7736 6.63184H7.22607V7.40764H9.58315V13.3691H10.4165V7.40764H12.7736V6.63184Z"
      fill="currentColor"
    />
  </svg>
);
